/**
 * Idő formázó metódusok string <=> int konverziókra
 */
class TimeFormatter
{
    /**
     * Felbontja az idő stringet percre és másodpercre
     *
     * Korábban `splitTime()`
     * 
     * @param  string time Az idő "mm:ss" formátumban (vezető nullák hiányozhatnak)
     * @return string[]    Egy [mm, ss] tömb
     */
    splitTimeString(time) {
        return time.split(':');
    }

    /**
     * Átalakítja az idő stringet intre
     *
     * Korábban `timeToSecond()`
     * 
     * @param  string time Az idő string
     * @return Number|int  Az idő számként
     */
    stringToInt(time) {
        let splitted = this.splitTimeString(time);

        if(time.charAt(0) === '-') {
            return Number(splitted[0]) * 60 - Number(splitted[1]);
        }

        return Number(splitted[0]) * 60 + Number(splitted[1]);
    }

    /**
     * Másodperc intet stringgé alakít vezető nullástul
     *
     * Korábban `secondToTime()`
     * 
     * @param  int|Number second  Az idő másodpercben
     * @param  string     joinStr Mivel legyen a perc és másodperc összeillesztve? (default ":")
     * @return string            Az idő "mm:ss" formában
     */
    intToString(second, joinStr = ':') {
        // Először vezető nullák nélkül csináljuk, majd egy másik metódussal kijavítjuk
        if(second < 0) {
            return '-'+this.fixFormat(Math.floor(Math.abs(second) / 60) + ':' + Math.floor(Math.abs(second) % 60), joinStr);
        }

        return this.fixFormat(Math.floor(second / 60) + ':' + Math.floor(second % 60), joinStr);
    }

    /**
     * A vezető nullákat kijavító metódus
     *
     * Korábban `timeFix()`
     * 
     * @param  string timeStr Az idő "mm:ss" formátumban
     * @param  string joinStr Mivel legyen a perc és másodperc összeillesztve? (default ":")
     * @return string         Az idő vezető nullákkal "mm:ss" formátumban (A ":" cserélhető)
     */
    fixFormat(timeStr, joinStr = ':') {
        let splitted = this.splitTimeString(timeStr);
        let seconds = splitted[1];
        let minutes = splitted[0];

        // Erre egy sprintf("%02d:%02d") tökéletes lenne, ha tudna ilyet a nyelv :)
        minutes = minutes.toString().length === 1 ? "0".concat(minutes.toString()) : minutes;
        seconds = seconds.toString().length === 1 ? "0".concat(seconds.toString()) : seconds;

        return minutes + joinStr + seconds;
    }
}

module.exports = new TimeFormatter();
