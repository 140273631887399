<template>
    <fragment>
        <div class="left-content">
            <div v-if="item.team === 'A'" class="content-outer">
                <div class="table-icon" v-b-tooltip.hover.top title="Rontott 6m-es lövés"/>
                <div class="table-jersey">{{item.player.jersey}}</div>
                <div class="table-player">{{item.player.name}}</div>
            </div>
        </div>
        <div class="main-time">
            <div class="time-outer">
                {{item.scores}}
            </div>
        </div>
        <div class="right-content">
            <div v-if="item.team === 'B'" class="content-outer">
                <div class="table-player">{{item.player.name}}</div>
                <div class="table-jersey">{{item.player.jersey}}</div>
                <div class="table-icon" v-b-tooltip.hover.top title="Rontott 6m-es lövés"/>
            </div>
        </div>
    </fragment>
</template>

<script>
    export default {
        name: 'ItemRontott6m',
        props: {
            item: Object,
        },
    }
</script>

<style scoped>
    .table-icon {
        background-image: url('/icon/rontott-6.svg');
    }
</style>