<template>
    <b-container fluid="lg">
        <div v-if="!items.length" class="main-container">
            <div class="itemsEmpty">
                A mérkőzés még nem kezdődött el.
            </div>
        </div>

        <div v-if="items.length" class="main-container">
            <div class="circle-row">
                <vue-circle
                        ref="attackA"
                        :progress="attackA"
                        :size="80"
                        :thickness="8"
                        line-cap="round"
                        :fill="fillRed"
                        empty-fill="rgba(0, 0, 0, 0.2)"
                        :animation="false"
                        :start-angle="1.5*Math.PI"
                        :show-percent="false"
                >
                    <div class="circle-number">{{attackA}}<span>%</span></div>
                </vue-circle>
                <div class="circle-title">
                    Lövés hatékonysága
                </div>
                <vue-circle
                        ref="attackB"
                        :progress="attackB"
                        :size="80"
                        :thickness="8"
                        line-cap="round"
                        :fill="fillGreen"
                        empty-fill="rgba(0, 0, 0, 0.2)"
                        :animation="false"
                        :start-angle="1.5*Math.PI"
                        :show-percent="false"
                >
                    <div class="circle-number">{{attackB}}<span>%</span></div>
                </vue-circle>
            </div>
            <div class="circle-row">
                <vue-circle
                        ref="defendA"
                        :progress="defendA"
                        :size="80"
                        :thickness="8"
                        line-cap="round"
                        :fill="fillRed"
                        empty-fill="rgba(0, 0, 0, 0.2)"
                        :animation="false"
                        :start-angle="1.5*Math.PI"
                        :show-percent="false"
                >
                    <div class="circle-number">{{defendA}}<span>%</span></div>
                </vue-circle>
                <div class="circle-title">
                    Védés hatékonysága
                </div>
                <vue-circle
                        ref="defendB"
                        :progress="defendB"
                        :size="80"
                        :thickness="8"
                        line-cap="round"
                        :fill="fillGreen"
                        empty-fill="rgba(0, 0, 0, 0.2)"
                        :animation="false"
                        :start-angle="1.5*Math.PI"
                        :show-percent="false"
                >
                    <div class="circle-number">{{defendB}}<span>%</span></div>
                </vue-circle>
            </div>

            <LineStatistics title="Összes lövés" :A="getStat('A', 'all')" :B="getStat('B', 'all')"/>
            <LineStatistics title="Összes gól" :A="getStat('A', 'success')" :B="getStat('B', 'success')"/>
            <LineStatistics title="6m lövés" :A="getStat('A', 'all', true)" :B="getStat('B', 'all', true)"/>
            <LineStatistics title="6m gól" :A="getStat('A', 'success', true)" :B="getStat('B', 'success', true)"/>
            <LineStatistics title="Eladott labda" :A="getStat('A', 'eladott')" :B="getStat('B', 'eladott')"/>
            <LineStatistics title="Technikai hiba" :A="getStat('A', 'technikai')" :B="getStat('B', 'technikai')"/>
            <LineStatistics title="Kiállítás" :A="getStat('A', 'kiallitas')" :B="getStat('B', 'kiallitas')"/>
        </div>
    </b-container>
</template>

<script>
    import Stat from '@/Statistics';
    import LineStatistics from '../LineStatistics';
    import VueCircle from 'vue2-circle-progress/src/index.vue';

    export default {
        name: 'StrandGameStatistics',
        components: {
            LineStatistics,
            VueCircle,
        },
        props: {
            id: String,
            szezonid: String,
            teams: Object,
            players: Object,
            statistics: Object,
            items: Array,
        },
        data: function () {
            return {
                s: Stat,
                fillRed : { gradient: ["#6C1722", "#f73051"], gradientAngle: 1.5*Math.PI },
                fillGreen : { gradient: ["#0F5C34", "#1cb568"], gradientAngle: 1.5*Math.PI },
            }
        },
        methods: {
            getStat(team, type, penalty) {
                return this.s.get(this.statistics, team, type, penalty);
            },
            attackRatio(team) {
                let self = this;
                let all = self.getStat(team, 'all');

                if (!all) {
                    all = 1;
                }

                let rtn = Math.round((self.getStat(team, 'success') / all) * 100);

                if(self.$refs['attack'+team]) {
                    self.$refs['attack'+team].updateProgress(rtn);
                }

                return rtn;
            },
            defendRatio(team) {
                let self = this;
                let invTeam = (team === 'A') ? 'B' : 'A';
                let all = self.getStat(team, 'shot');

                if (!all) {
                    all = 1;
                }

                let rtn = Math.round(((all - self.getStat(invTeam, 'success')) / all) * 100);

                if(self.$refs['defend'+team]) {
                    self.$refs['defend'+team].updateProgress(rtn);
                }

                return rtn;
            },
        },
        computed: {
            attackA: function () {
                return this.attackRatio('A');
            },
            attackB: function () {
                return this.attackRatio('B');
            },
            defendA: function () {
                return this.defendRatio('A');
            },
            defendB: function () {
                return this.defendRatio('B');
            },
        }
    }
</script>

<style scoped>
    .circle-row {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
        height: 80px;
        margin-bottom: 25px;
        position: relative;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
    }

    .circle-title {
        color: #000000;
        font-size: 18px;
        background-color: #ffffff;
        padding-left: 3px;
        padding-right: 3px;
        text-align: center;
        width: auto;
        margin-left: 20px;
        margin-right: 20px;
        line-height: 20px;
        position: relative;
        font-weight: bold;
    }

    .circle-row:before {
        display: block;
        content: "";
        position: absolute;
        z-index: 0;
        width: calc(100% - 200px);
        height: 2px;
        background-color: rgba(0, 0, 0, 0.2);
        margin-left: 100px;
        margin-right: 100px;
    }

    .circle {
        display: flex;
        width: 80px;
        height: 80px;
    }

    .circle-number {
        font-weight: bold;
        font-size: 18px;
    }

    .circle-number span {
        font-weight: normal;
        color: #868686;
    }
</style>