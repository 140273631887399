<template>
    <div>
        <TeremGameStatisticsScreen v-if="gameType === 'TEREM'" :id="id" :szezonid="szezonid" :teams="teams" :players="players" :items="items" :statistics="statistics"/>
        <StrandGameStatisticsScreen v-if="gameType === 'STRAND'" :id="id" :szezonid="szezonid" :teams="teams" :players="players" :items="items" :statistics="statistics"/>
    </div>
</template>

<script>
    import TeremGameStatisticsScreen from '../components/terem/TeremGameStatisticsScreen';
    import StrandGameStatisticsScreen from '../components/strand/StrandGameStatisticsScreen';

    export default {
        name: 'GameStatisticsScreen',
        components: {
            TeremGameStatisticsScreen,
            StrandGameStatisticsScreen,
        },
        props: {
            id: String,
            szezonid: String,
            teams: Object,
            players: Object,
            statistics: Object,
            items: Array,
            gameType: String,
        },
    }
</script>