<template>
    <b-container fluid="lg">
        <div v-if="!items.length" class="main-container">
            <div class="itemsEmpty">
                A mérkőzés még nem kezdődött el.
            </div>
        </div>

        <div v-if="items.length" class="main-container">
            <div class="buttons">
                <div class="button blue">Megosztás</div>
                <a class="button green" href="" target="_blank" download>PDF letöltés</a>
            </div>

            <div class="pdf-outer">
                <div class="pdf">
                    <div class="line row-title">
                        <div class="inic">A</div>
                        <div class="text bold center grow big1">MOL-PICK Szeged</div>
                        <div class="text center" style="width:10px;">-</div>
                        <div class="text bold center grow big1">Grundfos Tatabánya KC</div>
                        <div class="inic">B</div>
                    </div>
                    <div class="line">
                        <div class="left-corner">
                            <div class="title">verseny neve</div>
                            <div class="text">NB I férfi felnőtt</div>
                        </div>
                        <div class="right-corner">
                            <div class="title">szervezet</div>
                            <div class="text">MKSZ</div>
                        </div>
                    </div>
                    <hr/>
                    <div class="line">
                        <div class="block">
                            <div class="title">időpont</div>
                            <div class="text">2020.02.25 - kedd 18:30</div>
                        </div>
                        <div class="block">
                            <div class="title">forduló</div>
                            <div class="text">17.</div>
                        </div>
                        <div class="block">
                            <div class="title">szakág</div>
                            <div class="text">kézilabda</div>
                        </div>
                        <div class="block">
                            <div class="title">nem</div>
                            <div class="text">férfi</div>
                        </div>
                        <div class="block">
                            <div class="title">korosztály</div>
                            <div class="text">felnőtt</div>
                        </div>
                        <div class="block">
                            <div class="title">típus</div>
                            <div class="text">bajnoki (csoport)</div>
                        </div>
                        <div class="block">
                            <div class="title">idény</div>
                            <div class="text">2019/2020</div>
                        </div>
                        <div class="block">
                            <div class="title">mérkőzés kód</div>
                            <div class="text">1679</div>
                        </div>
                    </div>
                    <hr/>
                    <div class="line">
                        <div class="left-corner">
                            <div class="title">helyszín</div>
                            <div class="text">Városi Sportcsarnok, Szeged, Temesvári körút 33.</div>
                        </div>
                        <div class="right-corner">
                            <div class="title">nézőszám</div>
                            <div class="text">3 000</div>
                        </div>
                    </div>
                    <hr/>
                    <div class="line">
                        <div class="third block">
                            <div class="title mabo-5">játékvezető-1 / játékvezető-2</div>
                            <div class="text"><span class="bold">429</span> MEDVE RÓBERT PÁL</div>
                            <div class="text"><span class="bold">429</span> MEDVE PÁL</div>
                        </div>
                        <div class="third block">
                            <div class="title mabo-5">versenybíró-1 / versenybíró-2</div>
                            <div class="text"><span class="bold">429</span> MEDVE PÁL</div>
                            <div class="text"><span class="bold">429</span> MEDVE RÓBERT PÁL</div>
                        </div>
                        <div class="third block">
                            <div class="title mabo-5">játékvezető ellenőr - szövetségi ellenőr</div>
                            <div class="text"><span class="bold">429</span> MEDVE RÓBERT PÁL</div>
                        </div>
                    </div>
                    <hr/>
                    <div class="line">
                        <div class="pillar">
                            <div></div>
                            <div class="inic">A</div>
                            <div class="inic">B</div>
                        </div>
                        <div class="pillar grow">
                            <div></div>
                            <div class="text bold right big2">MOL-PICK Szeged</div>
                            <div class="text bold right big2">Grundfos Tatabánya KC</div>
                        </div>
                        <div class="pillar sep medium">
                            <div class="title">végeredmény</div>
                            <div class="text bold center">36</div>
                            <div class="text bold center">16</div>
                        </div>
                        <div class="pillar sep medium">
                            <div class="title">félidő</div>
                            <div class="text bold center">18</div>
                            <div class="text bold center">12</div>
                        </div>
                        <div class="pillar sep medium">
                            <div class="title">büntető</div>
                            <div class="text bold center"></div>
                            <div class="text bold center"></div>
                        </div>
                        <div class="pillar sep medium">
                            <div class="title">sorsolás</div>
                            <div class="text bold center"></div>
                            <div class="text bold center"></div>
                        </div>
                        <div class="pillar sep medium">
                            <div class="title">pont</div>
                            <div class="text bold center">2</div>
                            <div class="text bold center">0</div>
                        </div>
                        <div class="pillar sep medium">
                            <div class="title">gy d v</div>
                            <div class="text"><span class="bold">x</span> - -</div>
                            <div class="text">- - <span class="bold">x</span></div>
                        </div>
                        <div class="pillar sep medium">
                            <div class="title">időkérés</div>
                            <div class="text bold line">
                                <div class="space">13:51</div>
                                <div class="space">35:33</div>
                                <div class="space">55:57</div>
                            </div>
                            <div class="text bold line">
                                <div class="space">13:51</div>
                                <div class="space">35:33</div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <table>
                        <thead>
                            <tr>
                                <th class="title left">mez</th>
                                <th class="title left next-bless">játékosok neve</th>
                                <th class="bless"><div class="inic mini">A</div></th>
                                <th class="title">gól</th>
                                <th class="title">sárga</th>
                                <th class="title" colspan="3">2 perc kiállítás</th>
                                <th class="title">kizárás</th>
                                <th class="title">kizárás+</th>
                                <th class="title">7 m</th>
                                <th class="title">idő</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text bold left">16</td>
                                <td class="text left next-bless">MIKLER ROLAND</td>
                                <td class="text bold bless">K</td>
                                <td class="text">6/4</td>
                                <td class="text">17:03</td>
                                <td class="text third-cell"></td>
                                <td class="text third-cell">21:00</td>
                                <td class="text third-cell"></td>
                                <td class="text"></td>
                                <td class="text"></td>
                                <td class="text"></td>
                                <td class="text">29:15</td>
                            </tr>
                            <tr>
                                <td class="text bold left">16</td>
                                <td class="text left next-bless">MIKLER ROLAND</td>
                                <td class="text bold bless">K</td>
                                <td class="text">6/4</td>
                                <td class="text">17:03</td>
                                <td class="text third-cell"></td>
                                <td class="text third-cell">21:00</td>
                                <td class="text third-cell"></td>
                                <td class="text"></td>
                                <td class="text"></td>
                                <td class="text"></td>
                                <td class="text">29:15</td>
                            </tr>
                            <tr>
                                <td class="text bold left">16</td>
                                <td class="text left next-bless">MIKLER ROLAND</td>
                                <td class="text bold bless"></td>
                                <td class="text">6/4</td>
                                <td class="text">17:03</td>
                                <td class="text third-cell"></td>
                                <td class="text third-cell">21:00</td>
                                <td class="text third-cell"></td>
                                <td class="text"></td>
                                <td class="text"></td>
                                <td class="text"></td>
                                <td class="text">29:15</td>
                            </tr>
                            <tr>
                                <td class="text bold left">16</td>
                                <td class="text left next-bless">MIKLER ROLAND</td>
                                <td class="text bold bless"></td>
                                <td class="text">6/4</td>
                                <td class="text">17:03</td>
                                <td class="text third-cell"></td>
                                <td class="text third-cell">21:00</td>
                                <td class="text third-cell"></td>
                                <td class="text"></td>
                                <td class="text"></td>
                                <td class="text"></td>
                                <td class="text">29:15</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
    //import {eventBus} from "../main";

    export default {
        name: 'ReportScreen',
        props: {
            id: String,
            szezonid: String,
            teams: Object,
            players: Object,
            statistics: Object,
            items: Array,
            gameType: String,
        },
    }
</script>

<style scoped>
    @media screen and (max-width: 1199px) {
        .pdf-outer {
            width: 100%;
            overflow: auto;
        }

        .pdf {
            width: 1000px;
        }
    }

    .buttons {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        margin-bottom: 35px;
    }

    .button {
        width: 160px;
        height: 50px;
        border-radius: 25px;
        color: #ffffff;
        text-transform: uppercase;
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        cursor: pointer;
        transition: all 300ms;
    }

    .button.blue {
        margin-right: 15px;
        background-color: #2074d2;
    }

    .button.blue:hover {
        background-color: #2292ff;
    }

    .button.green {
        margin-left: 15px;
        background-color: #23c373;
    }

    .button.green:hover {
        background-color: #26ee8e;
    }

    .button:hover, .button:focus, .button:visited, .button:active {
        color: #ffffff;
    }

    .title {
        font-size: 13px;
        font-style: italic;
        letter-spacing: -0.26px;
        color: #757575;
    }

    .text {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.32px;
        color: #000000;
    }

    .bold {
        font-weight: 700;
    }

    .inic {
        width: 37px;
        height: 37px;
        background-color: #1d252d;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
    }

    .inic.mini {
        width: 24px;
        height: 24px;
        font-size: 16px;
        margin-left: auto;
        margin-right: auto;
    }

    hr {
        display: block;
        width: 100%;
        height: 1px;
        border: none;
        background-color: #979797;
        margin-top: 14px;
        margin-bottom: 14px;
    }

    .line {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
    }

    .row-title {
        border-top: 1px solid #1d252d;
        border-bottom: 1px solid #1d252d;
        margin-bottom: 10px;
    }

    .left-corner {
        width: 50%;
        text-align: left;
    }

    .right-corner {
        width: 50%;
        text-align: right;
    }

    .grow {
        flex-grow: 1;
    }

    .center {
        text-align: center;
    }

    .right {
        text-align: right;
    }

    .third {
        width: 33%;
    }

    .big1 {
        font-size: 19px;
        font-family: 'Rubik';
        letter-spacing: normal;
        width: calc(50% - 23.5px);
    }

    .big2 {
        font-size: 16px;
        font-family: 'Rubik';
        letter-spacing: normal;
    }

    .block {
        border-left: 1px solid #d5d5d5;
        padding-left: 22px;
        padding-right: 22px;
    }

    .third.block {
        padding-left: 50px;
        padding-right: 50px;
    }

    .block:first-child {
        border-left: none;
        padding-left: 0 !important;
    }

    .block:last-child {
        padding-right: 0 !important;
    }

    .mabo-5 {
        margin-bottom: 5px;
    }

    .pillar {
        display: flex;
        flex-flow: column;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: nowrap;
    }

    .pillar > div:not(.inic) {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .pillar:first-child > div:not(.inic) {
        padding-left: 0;
    }

    .pillar:last-child > div:not(.inic) {
        padding-right: 0;
    }

    .pillar > div:not(:first-child) {
        height: 37px;
        line-height: 37px;
    }

    .pillar > div:first-child {
        height: 20px;
        line-height: 20px;
    }

    .pillar > div:nth-child(2) {
        border-bottom: 1px solid #d5d5d5;
    }

    .sep {
        border-left: 1px solid #d5d5d5;
    }

    .space {
        padding-left: 10px;
        padding-right: 10px;
    }

    .medium .text {
        font-size: 18px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border: none;
    }

    th, td {
        border: 1px solid #d5d5d5;
        height: 34px;
        line-height: 34px;
        text-align: center;
    }

    th.left, td.left {
        text-align: left;
    }

    .next-bless {
        border-right: none;
    }

    .bless {
        border-left: none;
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    th {
        border-top: none;
        border-bottom: 1px solid #979797;
        font-weight: normal;
    }

    td:not(:first-child), th:not(:first-child) {
        padding-left: 15px;
    }

    td:not(:last-child), th:not(:last-child) {
        padding-right: 15px;
    }

    tr:last-child td {
        border-bottom: none;
    }

    td:first-child, th:first-child {
        border-left: none;
    }

    td:last-child, th:last-child {
        border-right: none;
    }
</style>