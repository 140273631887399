<template>
    <b-container id="header" fluid="lg">
        <b-row class="team-title">
            <b-col class="left-col">
                <div class="team-header" style="background-color: #f73051;">
                    <div class="team-name" style="text-align: left;">{{teams['A']['full']}}</div>
                    <div class="team-scores">
                        <div class="sum-score" v-b-tooltip.hover.left title="Eredmény">{{finalPointsA}}</div>
                        <div v-if="getHalfTimeGoals('A','1') || getHalfTimeGoals('B','1')" class="half-score" v-b-tooltip.hover.left title="1. félidő góljai">{{getHalfTimeGoals('A','1')}}</div>
                        <div v-if="getHalfTimeGoals('A','3') || getHalfTimeGoals('B','3')" class="half-score" v-b-tooltip.hover.left title="2. félidő góljai">{{getHalfTimeGoals('A','3')}}</div>
                        <div v-if="getHalfTimeGoals('A','12') || getHalfTimeGoals('B','12')" class="half-score" v-b-tooltip.hover.left title="Szétlövések">{{getHalfTimeGoals('A','12')}}</div>
                    </div>
                </div>
            </b-col>
            <b-col class="right-col">
                <div class="team-header" style="background-color: #1cb568;">
                    <div class="team-scores">
                        <div class="sum-score" v-b-tooltip.hover.right title="Eredmény">{{finalPointsB}}</div>
                        <div v-if="getHalfTimeGoals('A','1') || getHalfTimeGoals('B','1')" class="half-score" v-b-tooltip.hover.right title="1. félidő góljai">{{getHalfTimeGoals('B','1')}}</div>
                        <div v-if="getHalfTimeGoals('A','3') || getHalfTimeGoals('B','3')" class="half-score" v-b-tooltip.hover.right title="2. félidő góljai">{{getHalfTimeGoals('B','3')}}</div>
                        <div v-if="getHalfTimeGoals('A','12') || getHalfTimeGoals('B','12')" class="half-score" v-b-tooltip.hover.right title="Szétlövések">{{getHalfTimeGoals('B','12')}}</div>
                    </div>
                    <div class="team-name" style="text-align: right;">{{teams['B']['full']}}</div>
                </div>
            </b-col>
            <div class="halftimes" v-if="getHalfTimeGoals('A','1') || getHalfTimeGoals('B','1')">
                <div v-if="getHalfTimeGoals('A','1') || getHalfTimeGoals('B','1')" class="half-name" v-b-tooltip.hover.bottom title="1. félidő góljai">1</div>
                <div v-if="getHalfTimeGoals('A','3') || getHalfTimeGoals('B','3')" class="half-name" v-b-tooltip.hover.bottom title="2. félidő góljai">2</div>
                <div v-if="getHalfTimeGoals('A','12') || getHalfTimeGoals('B','12')" class="half-name" v-b-tooltip.hover.bottom title="Szétlövések">Sz</div>
            </div>
        </b-row>
        <div class="logos">
            <div class="team-logo">
                <img v-if="teams['A']['logo']" :src="teams['A']['logo']" :alt="teams['A']['full']" :title="teams['A']['full']">
            </div>
            <div class="match-data" v-if="matchdate || venue || competition">
                <div class="match-time" v-if="matchdate">{{ matchdate }}</div>
                <div class="match-place" v-if="venue">{{ venue }}</div>
                <div class="match-competition" v-if="competition">{{ competition }}</div>
            </div>
            <div class="team-logo">
                <img v-if="teams['B']['logo']" :src="teams['B']['logo']" :alt="teams['B']['full']" :title="teams['B']['full']">
            </div>
        </div>
        <b-row class="icon-stats">
            <b-col class="stat-box">
                <div class="mini-stat" v-b-tooltip.hover.top.html title="Lőtt gólok /<br> Összes lövés">
                    <img src="/icon/goal.svg" alt="Lőtt gólok" title="Lőtt gólok"/>
                    {{getStat('A', 'success') + '/' + getStat('A', 'all')}}
                </div>
                <div class="mini-stat" v-b-tooltip.hover.top.html title="6m-es gólok /<br> Összes 6m-es lövés">
                    <img src="/icon/6-m.svg" alt="6 méteres gólok" title="6 méteres gólok"/>
                    {{getStat('A', 'success', true) + '/' + (getStat('A', 'all', true))}}
                </div>
                <div class="mini-stat" :class="!getStat('A', 'kiallitas') ? 'empty' : ''" v-b-tooltip.hover.top title="2 perces kiállítások">
                    <img src="/icon/kiallitas.svg" alt="Kiállítások" title="Kiállítások"/>
                    {{getStat('A', 'kiallitas')}}
                </div>
                <div class="mini-stat" :class="!getStat('A', 'piroslap') ? 'empty' : ''" v-b-tooltip.hover.top title="Piros lapok">
                    <img src="/icon/red-card.svg" alt="Piros lapok" title="Piros lapok"/>
                    {{getStat('A', 'piroslap')}}
                </div>
            </b-col>
            <b-col class="stat-box">
                <div class="mini-stat" v-b-tooltip.hover.top.html title="Lőtt gólok /<br> Összes lövés">
                    <img src="/icon/goal.svg" alt="Lőtt gólok" title="Lőtt gólok"/>
                    {{getStat('B', 'success') + '/' + getStat('B', 'all')}}
                </div>
                <div class="mini-stat" v-b-tooltip.hover.top.html title="6m-es gólok /<br> Összes 6m-es lövés">
                    <img src="/icon/6-m.svg" alt="6 méteres gólok" title="6 méteres gólok"/>
                    {{getStat('B', 'success', true) + '/' + (getStat('B', 'all', true))}}
                </div>
                <div class="mini-stat" :class="!getStat('B', 'kiallitas') ? 'empty' : ''" v-b-tooltip.hover.top title="2 perces kiállítások">
                    <img src="/icon/kiallitas.svg" alt="Kiállítások" title="Kiállítások"/>
                    {{getStat('B', 'kiallitas')}}
                </div>
                <div class="mini-stat" :class="!getStat('B', 'piroslap') ? 'empty' : ''" v-b-tooltip.hover.top title="Piros lapok">
                    <img src="/icon/red-card.svg" alt="Piros lapok" title="Piros lapok"/>
                    {{getStat('B', 'piroslap')}}
                </div>
            </b-col>
        </b-row>
        <b-tabs id="main-nav" @input="changePage" v-model="tabIndex">
            <b-tab v-if="!disabled" title="Jegyzőkönyv" :disabled="disabled"/>
            <b-tab title="Események"/>
            <b-tab title="Játékos statisztikák"/>
            <b-tab title="Mérkőzés statisztikák"/>
        </b-tabs>
    </b-container>
</template>

<script>
    import Stat from '@/Statistics';

    export default {
        name: 'StrandHeader',
        props: {
            id: String,
            szezonid: String,
            teams: Object,
            statistics: Object,
            venue: String,
            matchdate: String,
            competition: String,
            age: String,
            gender: String,
            lastPeriod: String,
        },
        data: function () {
            return {
                tabIndex: 1,
                disabled: true,
                s: Stat,
            };
        },
        created() {
            this.tabIndex = this.$route.meta.tab;

            if(this.disabled) {
                this.tabIndex--;
            }
        },
        methods: {
            changePage(value) {
                let self = this;
                let index = value;

                if(self.disabled) {
                    index++;
                }

                let newPath = `/${self.id}/${self.getRoute(index)}`;

                if(self.$route.path !== newPath) {
                    self.$router.push({ path: newPath });
                }
            },
            getRoute(index) {
                let rtn = '';

                this.$router.options.routes.forEach(function (item) {
                    if(!rtn && item['meta'] && (item['meta']['tab'] || item['meta']['tab'] === 0) && item['meta']['tab'] === index) {
                        rtn = item['meta']['slug'];
                    }
                });

                return rtn;
            },
            getStat(team, type, penalty) {
                return this.s.get(this.statistics, team, type, penalty);
            },
            getHalfTimeGoals(team, halftime) {
                let rtn = 0;

                if(this.statistics && this.statistics[team] && this.statistics[team][halftime]) {
                    rtn = this.statistics[team][halftime].gol + 2*(this.statistics[team][halftime].gol6m) + 2*(this.statistics[team][halftime].gol2p);
                }

                return rtn;
            },
            teamFinalPoints(actualTeam, otherTeam) {
                let self = this;
                let rtn = 0;

                if(Number(self.lastPeriod) > 1 && self.getHalfTimeGoals(actualTeam,'1') > self.getHalfTimeGoals(otherTeam,'1')) {
                    rtn++;
                }

                if(Number(self.lastPeriod) > 3 && self.getHalfTimeGoals(actualTeam,'3') > self.getHalfTimeGoals(otherTeam,'3')) {
                    rtn++;
                }

                if(Number(self.lastPeriod) > 12 && self.getHalfTimeGoals(actualTeam,'12') > self.getHalfTimeGoals(otherTeam,'12')) {
                    rtn++;
                }

                return rtn;
            }
        },
        computed: {
            finalPointsA: function () {
                return this.teamFinalPoints('A', 'B');
            },
            finalPointsB: function () {
                return this.teamFinalPoints('B', 'A');
            }
        }
    }
</script>

<style scoped>
    #header {
        font-family: 'Rubik';
        padding-top: 35px;
    }

    .team-title {
        color: #ffffff;
        position: relative;
    }

    .halftimes {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: calc(50% - 12px);
        width: 24px;
        background-color: #ffffff;
        color: #000000;
        text-align: center;
        padding-bottom: 15px;
        padding-top: 3px;
    }

    .half-name {
        line-height: 22px;
        border-bottom: 2px solid #ffffff;
    }

    .left-col {
        padding-right: 0;
    }

    .right-col {
        padding-left: 0;
    }

    .left-col .team-header {
        padding: 15px 0 15px 35px;
    }

    .right-col .team-header {
        padding: 15px 35px 15px 0;
    }

    .team-header {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: nowrap;
    }

    .team-name {
        font-size: 24px;
        font-weight: bold;
        line-height: 60px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .team-scores {
        display: flex;
        flex-flow: column;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        width: 80px;
        min-width: 80px;
        text-align: center;
    }

    .sum-score {
        font-size: 30px;
        font-weight: 700;
        line-height: 38px;
    }

    .half-score {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        border-bottom: 2px solid #ffffff;
        width: 100%;
    }

    .logos {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .team-logo {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        width: 50%;
    }

    .team-logo img {
        max-height: 140px;
        max-width: 140px;
        width: auto;
        height: auto;
    }

    .match-data {
        width: 400px;
        margin-left: -200px;
        margin-right: -200px;
        text-align: center;
        font-size: 18px;
        line-height: 1.44;
        color: #000000;
    }

    .match-time {
        font-weight: bold;
    }

    .match-place {

    }

    .match-competition {
        font-size: 14px;
        font-style: italic;
    }

    .icon-stats {
        margin-bottom: 30px;
    }

    .stat-box {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        width: 50%;
    }

    .stat-box:nth-child(2) {
        flex-flow: row-reverse;
        flex-wrap: nowrap;
    }

    .mini-stat {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        padding-left: 20px;
        padding-right: 20px;
        white-space: nowrap;
        height: 26px;
        font-size: 14px;
        font-weight: bold;
        border-left: 1px solid #000000;
    }

    .stat-box:first-child .mini-stat:first-child {
        border-left: none;
    }
    .stat-box:last-child .mini-stat:last-child {
        border-left: none;
    }

    .mini-stat img {
        margin-right: 7px;
        display: block;
        margin-top: -1px;
    }

    .mini-stat.empty {
        color: #cccccc;
    }

    @media screen and (max-width: 1199px) {
        .mini-stat {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    @media screen and (max-width: 992px) {
        .stat-box {
            flex-wrap: wrap !important;
            margin: 0 auto;
            max-width: 360px;
        }

        .mini-stat {
            max-width: 110px;
            flex: 1 0 110px;
            border-left: 0;
            padding: 0;
        }

        .mini-stat:nth-child(n+4) {
            margin-top: 10px;
        }

        .match-data {
            width: 260px;
            margin-left: -130px;
            margin-right: -130px;
        }
    }

    @media screen and (max-width: 900px) {
        .team-title {
            margin-top: 0;
        }

        .team-title .col {
            padding: 0;
        }

        .team-title .col:first-child .team-header {
            padding: 10px 0px 10px 10px;
        }

        .team-title .col:last-child .team-header {
            padding: 10px 10px 10px 0px;
        }

        .team-name {
            font-size: 20px;
            line-height: 50px;
        }

        .sum-score {
            font-size: 24px;
            line-height: 30px;
        }

        .half-score {
            font-size: 16px;
            line-height: 20px;
        }

        .logos {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    @media screen and (max-width: 750px) {
        .stat-box {
            max-width: 250px;
        }

        .mini-stat {
            max-width: 110px;
            flex: 1 0 110px;
            border-left: 0;
            font-size: 16px;
        }
    }

    @media screen and (max-width: 640px) {
        .team-scores {
            width: 60px;
            min-width: 60px;
        }

        .match-data {
            order: -1;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }

        .logos {
            flex-wrap: wrap;
        }

        .team-name {
            font-size: 18px;
        }
    }

    @media screen and (max-width: 600px) {
      #header {
        padding-top: 0;
      }
    }

    @media screen and (max-width: 520px) {
        .stat-box {
            max-width: 230px;
        }

        .mini-stat {
            max-width: 100px;
            flex: 1 0 100px;
            border-left: 0;
        }
    }
</style>

<style>
    #main-nav .nav-tabs .nav-item {
        width: 33.334%;
        margin: 0;
    }

    #main-nav .tab-content {
        display: none;
    }

    #main-nav .nav-tabs {
        flex-wrap: nowrap;
    }

    #main-nav .nav-tabs .nav-item .nav-link {
        color: #ffffff;
        font-size: 14px;
        letter-spacing: 1px;
        text-align: center;
        text-transform: uppercase;
        height: 50px;
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        border: none;
        border-left: 1px solid #f0f3f6;
        border-radius: 0;
        background-color: #1d252d;
        width: 100%;
        transition: all 300ms;
    }

    #main-nav .nav-tabs .nav-item:first-child .nav-link {
        border-left: none;
        border-top-left-radius: 8px;
    }

    #main-nav .nav-tabs .nav-item:last-child .nav-link {
        border-top-right-radius: 8px;
    }

    #main-nav .nav-tabs .nav-item .nav-link.disabled {
        background-color: #1d252d;
        color: #808080;
    }

    #main-nav .nav-tabs .nav-item .nav-link.active {
        background-color: #23c373;
        color: #ffffff;
    }

    #main-nav .nav-tabs .nav-item .nav-link:not(.disabled):not(.active):hover {
        background-color: #475f69;
    }

    @media screen and (max-width: 750px) {
        #main-nav .nav-tabs {
            flex-wrap: wrap;
        }

        #main-nav .nav-tabs .nav-item {
            width: 100%;
        }

        #main-nav .nav-tabs .nav-item:first-child .nav-link {
            border-top-right-radius: 8px;
        }

        #main-nav .nav-tabs .nav-item:last-child .nav-link {
            border-top-right-radius: 0;
        }

        #main-nav .nav-tabs .nav-item {
            border-bottom: 1px solid #ffffff;
        }

        #main-nav .nav-tabs .nav-item:last-child {
            border-bottom: none;
        }
    }
</style>
