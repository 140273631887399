<template>
    <div class="shooting-stats">
        <div class="outer">
            <div class="field">
                <Bubble :item="fieldArea['0']" top="20px" left="90%" tooltip="Jobbszélső"/>
                <Bubble :item="fieldArea['1']" top="125px" left="80%" tooltip="Jobb közép"/>
                <Bubble :item="fieldArea['2']" top="150px" left="50%" tooltip="Közép"/>
                <Bubble :item="fieldArea['3']" top="125px" left="20%" tooltip="Bal közép"/>
                <Bubble :item="fieldArea['4']" top="20px" left="10%" tooltip="Balszélső"/>
                <Bubble :item="fieldArea['5']" top="250px" left="85%" tooltip="Jobb távol"/>
                <Bubble :item="fieldArea['6']" top="220px" left="50%" tooltip="Közép távol"/>
                <Bubble :item="fieldArea['7']" top="250px" left="15%" tooltip="Bal távol"/>
                <Bubble :item="fieldArea['8']" top="40px" left="81%" tooltip="Jobb beálló"/>
                <Bubble :item="fieldArea['9']" top="95px" left="40%" tooltip="Közép beálló"/>
                <Bubble :item="fieldArea['10']" top="40px" left="19%" tooltip="Bal beálló"/>
                <Bubble :item="fieldArea['11']" top="65px" left="75%" tooltip="Jobb betörés"/>
                <Bubble :item="fieldArea['12']" top="95px" left="60%" tooltip="Közép betörés"/>
                <Bubble :item="fieldArea['13']" top="65px" left="25%" tooltip="Bal betörés"/>
                <Bubble :item="fieldArea['14']" top="30px" left="40%" tooltip="Kínai"/>
                <Bubble :item="fieldArea['15']" top="30px" left="60%" tooltip="Gyorsindítás"/>
                <Bubble :item="fieldArea['16']" top="290px" left="50%" tooltip="Saját térfél"/>
                <Bubble :item="fieldArea['17']" top="110px" left="50%" tooltip="6 méteres" :blue="true"/>
            </div>
        </div>
        <div class="outer">
            <div class="gate">
                <div class="gate-row">
                    <GoalArea :item="goalArea['1']"/>
                    <GoalArea :item="goalArea['2']"/>
                    <GoalArea :item="goalArea['3']"/>
                </div>
                <div class="gate-row">
                    <GoalArea :item="goalArea['4']"/>
                    <GoalArea :item="goalArea['5']"/>
                    <GoalArea :item="goalArea['6']"/>
                </div>
                <div class="gate-row">
                    <GoalArea :item="goalArea['7']"/>
                    <GoalArea :item="goalArea['8']"/>
                    <GoalArea :item="goalArea['9']"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Bubble from '../Bubble';
    import GoalArea from '../GoalArea';

    export default {
        name: 'StrandShotStatistics',
        components: {
            GoalArea,
            Bubble,
        },
        props: {
            items: Array,
        },
        methods: {
            isGoalShot(action) {
                return ['gol', 'gol2p', 'gol6m', 'vedett', 'vedett6m', 'rontott', 'rontott6m', 'kapufa', 'kapufa6m'].includes(action);
            },
        },
        computed: {
            fieldArea: function() {
                let self = this;
                let rtn = {};
                let sum = 0;

                for (let i = 0; i <= 17; i++) {
                    rtn[i.toString()] = {
                        goal: 0,
                        defended: 0,
                        shots: 0,
                        ratio: 0,
                        render: '',
                    };
                }

                this.items.forEach(function (item) {
                    if(self.isGoalShot(item.action)) {
                        let index = '';

                        if(item.selectedArea) {
                            index = item.selectedArea.toString();
                        }

                        if(['gol6m', 'vedett6m', 'rontott6m', 'kapufa6m'].includes(item.action)) {
                            index = '17';
                        }

                        sum++;

                        if(index) {
                            rtn[index].shots++;

                            if (item.action === "gol2p" || item.action === "gol6m") {
                                rtn[index].goal+=2;
                            } else if (item.action === "gol") {
                                rtn[index].goal++;
                            } else {
                                rtn[index].defended++;
                            }
                        }
                    }
                });

                this.items.forEach(function (item) {
                    if(self.isGoalShot(item.action)) {
                        let index = '';

                        if(item.selectedArea) {
                            index = item.selectedArea.toString();
                        }

                        if(['gol6m', 'vedett6m', 'rontott6m', 'kapufa6m'].includes(item.action)) {
                            index = '17';
                        }

                        if(index) {
                            if(rtn[index].shots) {
                                let ratio = rtn[index].shots / sum;

                                rtn[index].ratio = ratio < 0.1 ? 0.1 : ratio;
                                rtn[index].render = rtn[index].defended + '/' + rtn[index].shots;
                            }
                        }
                    }
                });

                return rtn;
            },
            goalArea: function() {
                let rtn = {};
                let sum = 0;

                for (let i = 1; i <= 9; i++) {
                    rtn[i.toString()] = {
                        goal: 0,
                        shots: 0,
                        defended: 0,
                        ratio: 0,
                        render: '',
                    };
                }

                this.items.forEach(function (item) {
                    if(item.selectedGateArea) {
                        let index = item.selectedGateArea.toString();

                        sum++;
                        rtn[index].shots++;

                        if (item.action === "gol2p" || item.action === "gol6m") {
                            rtn[index].goal+=2;
                        } else if (item.action === "gol") {
                            rtn[index].goal++;
                        } else {
                            rtn[index].defended++;
                        }
                    }
                });

                this.items.forEach(function (item) {
                    if (item.selectedGateArea) {
                        let index = item.selectedGateArea.toString();

                        if(rtn[index].shots) {
                            let ratio = rtn[index].shots / sum;

                            rtn[index].ratio = ratio < 0.1 ? 0.1 : ratio;
                            rtn[index].render = rtn[index].defended + '/' + rtn[index].shots;
                        }
                    }
                });

                return rtn;
            }
        },
    }
</script>

<style scoped>
    .shooting-stats {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
    }

    .outer {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        width: 50%;
        height: 334px;
        margin-bottom: 25px;
    }

    .field {
        width: 378px;
        height: 334px;
        background-image: url('/image/field.png');
        background-size: 378px 334px;
    }

    .gate {
        width: 270px;
        height: 180px;
        background-image: url('/image/gate_full.png');
        background-size: 270px 180px;
        padding: 7px 7px 0 7px;

    }

    .field, .gate {
        position: relative;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .gate-row {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        width: 100%;
        height: 58px;
    }

    @media screen and (max-width: 992px) {
        .shooting-stats {
            flex-wrap: wrap;
        }

        .outer {
            width: 100%;
            height: auto;
        }
    }
</style>