<template>
    <div class="goal-area">
        <div v-if="item.shots" class="goal-area-back" :style="'opacity: '+item.ratio"/>
        <span v-if="item.shots">{{item.render}}</span>
    </div>
</template>

<script>
    export default {
        name: 'GoalArea',
        props: {
            item: Object,
        },
    }
</script>

<style scoped>
    .goal-area {
        width: 33.33%;
        line-height: 58px;
        height: 100%;
        text-align: center;
        position: relative;
        z-index: 1;
        border: 0.5px solid transparent;
    }

    .goal-area-back {
        background-color: rgba(247, 48, 81, 0.75);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
    }
</style>