function config() {
    // ez a public socket!
    this.socket_host = 'http://localhost';
    this.socket_port = 3002;
    this.socket_debug = false;

    this.api_url = "https://dev.bo.mksz.hu";

    // this.sentry_dsn = "https://5dab922e49e14500a88d072321c926a0@sentry.docker.zengo.eu/65";
    this.sentry_dsn = false;
}

module.exports = new config();