import Vue from 'vue';
import VueRouter from 'vue-router';
import ReportScreen from '@/screens/ReportScreen';
import TimelineScreen from '@/screens/TimelineScreen';
import PlayerStatisticsScreen from '@/screens/PlayerStatisticsScreen';
import GameStatisticsScreen from '@/screens/GameStatisticsScreen';
import NotFound from '@/screens/NotFound';

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/:id/jegyzokonyv',
            component: ReportScreen,
            title: 'Jegyzőkönyv',
            meta: {
                tab: 0,
                slug: 'jegyzokonyv',
            }
        },
        {
            path: '/:id/esemenyek',
            component: TimelineScreen,
            title: 'Események',
            meta: {
                tab: 1,
                slug: 'esemenyek',
            }
        },
        {
            path: '/:id/jatekos_statisztikak',
            component: PlayerStatisticsScreen,
            title: 'Játékos statisztikák',
            meta: {
                tab: 2,
                slug: 'jatekos_statisztikak',
            }
        },
        {
            path: '/:id/merkozes_statisztikak',
            component: GameStatisticsScreen,
            title: 'Mérkőzés statisztikák',
            meta: {
                tab: 3,
                slug: 'merkozes_statisztikak',
            }
        },
        {
            path: '/404_not_found',
            component: NotFound,
            title: '404 - Oldal nem található',
        },
        {
            path: '/:id/',
            redirect: to => {
                // @TODO JELENEG mivel a socketben nem feltétlen szám, hanem most string is lehet a meccs azonosító, ezért nem jó az isnan vizsgálat
                if(typeof to.params.id !== 'undefined' && to.params.id !== '' )
                {
                    return `/${to.params.id}/esemenyek`;
                }

                return '/404_not_found';
            },
        },
    ]
})
