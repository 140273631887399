import Vue from 'vue'
import App from './App'
import router from '@/plugins/router';

// bootstrap 4.5
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { Plugin } from 'vue-fragment';

export const eventBus = new Vue();

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Plugin);

new Vue({
    //el: '#app',
    router,
    components: {BootstrapVue, BootstrapVueIcons},
    render: h => h(App),
}).$mount('#app');