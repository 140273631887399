<template>
    <div>
        <TeremHeader v-if="(gameType === 'TEREM')" :id="id" :szezonid="szezonid" :teams="teams" :statistics="statistics" :matchdate="matchdate" :venue="venue" :competition="competition" :age="age" :gender="gender"/>
        <StrandHeader v-if="(gameType === 'STRAND')" :id="id" :szezonid="szezonid" :teams="teams" :statistics="statistics" :matchdate="matchdate" :venue="venue" :competition="competition" :age="age" :gender="gender" :lastPeriod="lastPeriod"/>
    </div>
</template>

<script>
    import TeremHeader from '../components/terem/TeremHeader';
    import StrandHeader from '../components/strand/StrandHeader';

    export default {
        name: 'Header',
        components: {
            TeremHeader,
            StrandHeader
        },
        props: {
            id: String,
            szezonid: String,
            teams: Object,
            statistics: Object,
            venue: String,
            matchdate: String,
            competition: String,
            age: String,
            gender: String,
            gameType: String,
            lastPeriod: String,
        },
    }
</script>