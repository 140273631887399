<template>
    <fragment>
        <div class="left-content" :class="item.team === 'B' ? 'defender' : ''">
            <div v-if="item.team === 'A' || item.defended.name" class="content-outer">
                <div class="table-icon" :class="item.team === 'B' ? 'blind' : ''" v-b-tooltip.hover.top :title="item.team === 'A' ? 'Védett lövés' : item.defended.jersey ? 'Kapus védte' : ''"/>
                <div class="table-jersey">{{item.team === 'A' ? item.player.jersey : item.defended.jersey}}</div>
                <div class="table-player">{{item.team === 'A' ? item.player.name : item.defended.name}}</div>
            </div>
        </div>
        <div class="main-time goal">
            <div class="time-outer">
                {{item.scores}}
            </div>
        </div>
        <div class="right-content" :class="item.team === 'A' ? 'defender' : ''">
            <div v-if="item.team === 'B' || item.defended.name" class="content-outer">
                <div class="table-player">{{item.team === 'B' ? item.player.name : item.defended.name}}</div>
                <div class="table-jersey">{{item.team === 'B' ? item.player.jersey : item.defended.jersey}}</div>
                <div class="table-icon" :class="item.team === 'A' ? 'blind' : ''" v-b-tooltip.hover.top :title="item.team === 'B' ? 'Védett lövés' : item.defended.jersey ? 'Kapus védte' : ''"/>
            </div>
        </div>
    </fragment>
</template>

<script>
    export default {
        name: 'ItemVedett',
        props: {
            item: Object,
        },
    }
</script>

<style scoped>
    .table-icon.blind {
        background-image: url('/icon/vedett-kapu.svg');
    }

    .table-icon:not(.blind) {
        background-image: url('/icon/vedett.svg');
    }
</style>