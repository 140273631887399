<template>
    <b-container fluid="lg">
        <div v-if="!items.length" class="main-container">
            <div class="itemsEmpty">
                A mérkőzés még nem kezdődött el.
            </div>
        </div>

        <div v-if="items.length" class="main-container">
            <div class="teams">
                <div class="team-tab">
                    <div class="team-inner" :class="team === 'A' ? 'active' : ''" @click="activeTab('A')">
                        {{teams['A']['full']}}
                    </div>
                </div>
                <div class="team-tab">
                    <div class="team-inner" :class="team === 'B' ? 'active' : ''" @click="activeTab('B')">
                        {{teams['B']['full']}}
                    </div>
                </div>
            </div>

            <div class="outer">
                <div class="inner">
                    <div class="stat-items">
                        <div class="stat-item" v-b-tooltip.hover.top.html title="Sikeres védések /<br> Összes kapura érkezett lövés">
                            <div class="stat-icon defend-icon"/>
                            <div class="stat-text">{{getStat('defendedSuccess') + getStat('defendedSuccessPenalty') + '/' + getStat('shot')}}</div>
                        </div>
                        <div class="stat-item" v-b-tooltip.hover.top.html title="Lőtt gólok /<br> Összes lövés">
                            <div class="stat-icon goal-icon"/>
                            <div class="stat-text">{{getStat('success') + '/' + getStat('all')}}</div>
                        </div>
                        <div class="stat-item" v-b-tooltip.hover.top title="Lőtt gólok aránya">
                            <div class="stat-icon percent-icon"/>
                            <div class="stat-text">{{getStat('all') ? getGlobalShotsRatio()+'%' : '0%'}}</div>
                        </div>
                        <div class="stat-item" v-b-tooltip.hover.top.html title="7m-es gólok /<br> Összes 7m-es lövés">
                            <div class="stat-icon penaltyshot-icon"/>
                            <div class="stat-text">{{getStat('success', true) + '/' + (getStat('all', true))}}</div>
                        </div>
                        <div class="stat-item" v-b-tooltip.hover.top title="Sárga lapok">
                            <div class="stat-icon yellow-icon"/>
                            <div class="stat-text" :class="!getStat('sargalap') ? 'empty' : ''">{{getStat('sargalap')}}</div>
                        </div>
                        <div class="stat-item" v-b-tooltip.hover.top title="2 perces kiállítások">
                            <div class="stat-icon exclude-icon"/>
                            <div class="stat-text" :class="!getStat('kiallitas') ? 'empty' : ''">{{getStat('kiallitas')}}</div>
                        </div>
                        <div class="stat-item" v-b-tooltip.hover.top title="Piros lap">
                            <div class="stat-icon red-icon"/>
                            <div class="stat-text" :class="!getStat('piroslap') ? 'empty' : ''">{{getStat('piroslap')}}</div>
                        </div>
                        <div class="stat-item" v-b-tooltip.hover.top title="Kék lap">
                            <div class="stat-icon blue-icon"/>
                            <div class="stat-text" :class="!getStat('keklap') ? 'empty' : ''">{{getStat('keklap')}}</div>
                        </div>
                      <div class="stat-item" v-b-tooltip.hover.top title="Pályán töltött átlag idő">
                        <div class="stat-icon clock-icon"/>
                        <div class="stat-text">{{avgPlayTime}}</div>
                      </div>
                    </div>

                    <div class="goalkeepers">
                        <div v-for="(player, i) in goalkeepers" :key="player.id+'_'+player.team" class="table-row" :class="i%2 ? 'odd' : 'even'" @click="showPlayerStatistics(player)">
                            <div class="player-jersey">{{player.jersey}}</div>
                            <div class="player-name">{{player.name}}</div>
                            <div class="player-goalkeeper">K</div>
                            <div class="nowrap" v-b-tooltip.hover.top :title="player.statistics.defendedSuccess+player.statistics.defendedSuccessPenalty+player.statistics.defendedFailed+player.statistics.defendedFailedPenalty ? Math.round((player.statistics.defendedSuccess+player.statistics.defendedSuccessPenalty) / (player.statistics.defendedSuccess+player.statistics.defendedSuccessPenalty+player.statistics.defendedFailed+player.statistics.defendedFailedPenalty) * 100) + '%' : ''">{{ifExistRatio((player.statistics.defendedSuccess+player.statistics.defendedSuccessPenalty) + '/' +(player.statistics.defendedSuccess+player.statistics.defendedSuccessPenalty+player.statistics.defendedFailed+player.statistics.defendedFailedPenalty))}}</div>
                            <div class="nowrap">{{ifExistRatio(s.getSuccessShots(player.statistics) + '/' + (s.getSuccessShots(player.statistics)+s.getFailedShots(player.statistics)))}}</div>
                            <div class="nowrap">{{(s.getSuccessShots(player.statistics)+s.getFailedShots(player.statistics)) ? getShotsRatio(player.statistics)+'%' : ''}}</div>
                            <div class="nowrap">{{ifExistRatio((player.statistics.gol7m) + '/' + (player.statistics.gol7m+player.statistics.vedett7m+player.statistics.rontott7m+player.statistics.kapufa7m))}}</div>
                            <div class="nowrap">{{ifExist(player.statistics.sargalap)}}</div>
                            <div class="nowrap">{{ifExist(player.statistics.kiallitas)}}</div>
                            <div class="nowrap">{{ifExist(player.statistics.piroslap)}}</div>
                            <div class="nowrap">{{ifExist(player.statistics.keklap)}}</div>
                            <div class="nowrap">{{ playedTime[player.id] }}</div>
                        </div>
                    </div>

                    <div class="players">
                        <div v-for="(player, i) in normalPlayers" :key="player.id+'_'+player.team" class="table-row" :class="i%2 ? 'odd' : 'even'" @click="showPlayerStatistics(player)">
                            <div class="player-jersey">{{player.jersey}}</div>
                            <div class="player-name">{{player.name}}</div>
                            <div class="nowrap">{{ifExistRatio(s.getSuccessShots(player.statistics) + '/' + (s.getSuccessShots(player.statistics)+s.getFailedShots(player.statistics)))}}</div>
                            <div class="nowrap">{{(s.getSuccessShots(player.statistics)+s.getFailedShots(player.statistics)) ? getShotsRatio(player.statistics)+'%' : ''}}</div>
                            <div class="nowrap">{{ifExistRatio((player.statistics.gol7m) + '/' + (player.statistics.gol7m+player.statistics.vedett7m+player.statistics.rontott7m+player.statistics.kapufa7m))}}</div>
                            <div class="nowrap">{{ifExist(player.statistics.sargalap)}}</div>
                            <div class="nowrap">{{ifExist(player.statistics.kiallitas)}}</div>
                            <div class="nowrap">{{ifExist(player.statistics.piroslap)}}</div>
                            <div class="nowrap">{{ifExist(player.statistics.keklap)}}</div>
                            <div class="nowrap">{{ playedTime[player.id] }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="player-statistics"
                 title="Játékos részletek"
                 :hide-footer="true"
                 size="lg"
                 @hidden="resetModal">
            <div class="modal-name">{{selectedPlayer.jersey + ' ' +selectedPlayer.name}} <span class="modal-year" v-if="false">(25)</span></div>
            <div class="modal-team">{{teams[team].full}}</div>

            <div class="modal-items">
                <div v-if="selectedPlayer.goalkeeper" class="modal-item" v-b-tooltip.hover.top title="Sikeres védések / Összes kapura érkezett lövés">
                    <div class="stat-icon defend-icon"/>
                    <div class="stat-text">{{playerStat.defended}}</div>
                </div>
                <div v-if="selectedPlayer.goalkeeper" class="modal-item" v-b-tooltip.hover.top title="Védett lövések aránya">
                    <div class="stat-icon red-percent-icon"/>
                    <div class="stat-text">{{playerStat.defendRatio}}</div>
                </div>
                <div class="modal-item" v-b-tooltip.hover.top title="Lőtt gólok / Összes lövés">
                    <div class="stat-icon goal-icon"/>
                    <div class="stat-text">{{playerStat.attack}}</div>
                </div>
                <div class="modal-item" v-b-tooltip.hover.top title="Lőtt gólok aránya">
                    <div class="stat-icon percent-icon"/>
                    <div class="stat-text">{{playerStat.ratio}}</div>
                </div>
                <div class="modal-item" v-b-tooltip.hover.top title="7m-es gólok / Összes 7m-es lövés">
                    <div class="stat-icon penaltyshot-icon"/>
                    <div class="stat-text">{{playerStat.attack7m}}</div>
                </div>
                <div class="modal-item" v-b-tooltip.hover.top title="Sárga lapok">
                    <div class="stat-icon yellow-icon"/>
                    <div class="stat-text" :class="!selectedPlayer.statistics.sargalap ? 'empty' : ''">{{selectedPlayer.statistics.sargalap}}</div>
                </div>
                <div class="modal-item" v-b-tooltip.hover.top title="2 perces kiállítások">
                    <div class="stat-icon exclude-icon"/>
                    <div class="stat-text" :class="!selectedPlayer.statistics.kiallitas ? 'empty' : ''">{{selectedPlayer.statistics.kiallitas}}</div>
                </div>
                <div class="modal-item" v-b-tooltip.hover.top title="Piros lapok">
                    <div class="stat-icon red-icon"/>
                    <div class="stat-text" :class="!selectedPlayer.statistics.piroslap ? 'empty' : ''">{{selectedPlayer.statistics.piroslap}}</div>
                </div>
                <div class="modal-item" v-b-tooltip.hover.top title="Kék lapok">
                    <div class="stat-icon blue-icon"/>
                    <div class="stat-text" :class="!selectedPlayer.statistics.keklap ? 'empty' : ''">{{selectedPlayer.statistics.keklap}}</div>
                </div>
                <div v-if="playedTime[selectedPlayer.id]" class="modal-item" v-b-tooltip.hover.top title="Pályán töltött idő">
                    <div class="stat-icon clock-icon"/>
                    <div class="stat-text">{{playedTime[selectedPlayer.id]}}</div>
                </div>
            </div>

            <div class="mini-tabs">
                <div class="mini-tab" :class="modalPage === 0 ? 'active' : ''" @click="modalPage = 0">Események</div>
                <div class="mini-tab" :class="modalPage === 1 ? 'active' : ''" @click="modalPage = 1">Lövési statisztikák</div>
                <div class="mini-tab" :class="modalPage === 2 ? 'active' : ''" @click="modalPage = 2" v-if="selectedPlayer.goalkeeper">Védési statisztikák</div>
            </div>

            <TeremTimeline v-if="modalPage === 0" :items="[...selectedItems, ...selectedItemsDefended]"/>
            <TeremShotStatistics v-if="modalPage === 1" :items="selectedItems" :player="selectedPlayer"/>
            <TeremDefendStatistics v-if="modalPage === 2 && selectedPlayer.goalkeeper" :items="selectedItemsDefended"/>
        </b-modal>
    </b-container>
</template>

<script>
    import TimeFormatter from '@/TimeFormatter';
    import Stat from '@/Statistics';
    import TeremTimeline from '../terem/TeremTimeline';
    import TeremShotStatistics from '../terem/TeremShotStatistics';
    import TeremDefendStatistics from '../terem/TeremDefendStatistics';

    export default {
        name: 'TeremPlayerStatistics',
        components: {
            TeremTimeline,
            TeremShotStatistics,
            TeremDefendStatistics,
        },
        props: {
            id: String,
            szezonid: String,
            teams: Object,
            players: Object,
            statistics: Object,
            items: Array,
            playtime: Object
        },
        data: function () {
            return {
                team: 'A',
                s: Stat,
                modalPage: 0,
                selectedPlayer: {
                    statistics: {
                        defendedFailed: 0,
                        defendedFailedPenalty: 0,
                        defendedSuccess: 0,
                        defendedSuccessPenalty: 0,
                        gol: 0,
                        gol7m: 0,
                        rontott: 0,
                        rontott7m: 0,
                        vedett: 0,
                        vedett7m: 0,
                        kapufa: 0,
                        kapufa7m: 0,
                        eladott: 0,
                        technikai: 0,
                        kiallitas: 0,
                        sargalap: 0,
                        piroslap: 0,
                        keklap: 0,
                        timeout: 0,
                    }
                }
            };
        },
        methods: {
            ifExist(value) {
                if(value) {
                    return value;
                }

                return '';
            },
            ifExistRatio(value) {
                if(value !== '0/0') {
                    return value;
                }

                return '';
            },
            getStat(type, penalty) {
                return this.s.get(this.statistics, this.team, type, penalty);
            },
            getGlobalShotsRatio() {
                let sumShots = this.getStat('all');

                if(!sumShots) {
                    return 0;
                }

                return Math.round((this.getStat('success') / sumShots) * 100);
            },
            getShotsRatio(player) {
                let sumShots = this.s.getSuccessShots(player) + this.s.getFailedShots(player);

                if(!sumShots) {
                    return 0;
                }

                return Math.round((this.s.getSuccessShots(player) / sumShots) * 100);
            },
            activeTab(newTeam) {
                if(this.team !== newTeam) {
                    this.team = newTeam;
                }
            },
            showPlayerStatistics(player) {
                this.selectedPlayer = player;
                this.$bvModal.show('player-statistics');
            },
            resetModal() {
                this.modalPage = 0;
                this.selectedPlayer = {
                    statistics: {
                        defendedFailed: 0,
                        defendedFailedPenalty: 0,
                        defendedSuccess: 0,
                        defendedSuccessPenalty: 0,
                        gol: 0,
                        gol7m: 0,
                        rontott: 0,
                        rontott7m: 0,
                        vedett: 0,
                        vedett7m: 0,
                        kapufa: 0,
                        kapufa7m: 0,
                        eladott: 0,
                        technikai: 0,
                        kiallitas: 0,
                        sargalap: 0,
                        piroslap: 0,
                        keklap: 0,
                        timeout: 0,
                    }
                };
            },
            getStatistics(type) {
                if(!this.selectedPlayer.statistics || !this.selectedPlayer.statistics[type]) {
                    return 0;
                }

                return this.selectedPlayer.statistics[type];
            },
            getPlayerPlaytime(player_id) {
                if(typeof this.playtime[player_id] !== "undefined") {
                    if(this.playtime[player_id].elapsed > 0) {
                        return this.playtime[player_id].elapsedString;
                    }
                }
                return '';
            }
        },
        computed: {
            playedTime: function() {
                let self = this;
                let rtn = {};

                self.players[self.team].forEach(function (player) {
                    rtn = {
                        ...rtn,
                        [player.id]: self.getPlayerPlaytime(player.id),
                    };
                });

                return rtn;
            },
            avgPlayTime: function() {
                let playersPlayed = 0;
                let playersTime = 0;
                let self = this;

                self.players[self.team].forEach(function (player) {
                    if(!player.entry && !player.official) {
                        if(self.playtime[player.id] && self.playtime[player.id].elapsed > 0) {
                            playersPlayed++;
                            playersTime += self.playtime[player.id].elapsed;
                        }
                    }
                });

                if(!playersTime || !playersPlayed) {
                    return '';
                }

                return TimeFormatter.intToString(Math.round(playersTime / playersPlayed));
            },
            goalkeepers: function () {
                return this.players[this.team].filter(function (item) {
                    return item.goalkeeper && !item.entry && !item.official;
                });
            },
            normalPlayers: function () {
                return this.players[this.team].filter(function (item) {
                    return !item.goalkeeper && !item.entry && !item.official;
                });
            },
            selectedItems: function () {
                let self = this;

                if(self.selectedPlayer.id) {
                    return this.items.filter(function (item) {
                        return item.player && item.player.id === self.selectedPlayer.id;
                    });
                }

                return [];
            },
            selectedItemsDefended: function () {
                let self = this;

                if(self.selectedPlayer.id) {
                    return this.items.filter(function (item) {
                        return item.defended && item.defended.id === self.selectedPlayer.id;
                    });
                }

                return [];
            },
            playerStat: function () {
                let defended = this.getStatistics('defendedSuccess') + this.getStatistics('defendedSuccessPenalty');
                let defendedAll = this.getStatistics('defendedFailed') + this.getStatistics('defendedFailedPenalty') + defended;
                let success = this.getStatistics('gol') + this.getStatistics('gol7m');
                let count = this.getStatistics('vedett') + this.getStatistics('vedett7m');
                let sum = this.getStatistics('rontott') + this.getStatistics('rontott7m') + this.getStatistics('kapufa') + this.getStatistics('kapufa7m') + count + success;

                return {
                    defended: defended + '/' + defendedAll,
                    attack: success + '/' + sum,
                    attack7m: this.getStatistics('gol7m') + '/' + (this.getStatistics('gol7m') + this.getStatistics('vedett7m') + this.getStatistics('rontott7m') + this.getStatistics('kapufa7m')),
                    ratio: sum ? Math.round(success / sum * 100) + '%' : 0,
                    defendRatio: defendedAll ? Math.round(defended / defendedAll * 100) + '%' : 0,
                }
            }
        },
    }
</script>

<style scoped>
    #player-statistics {
        padding-left: 0 !important;
    }

    @media screen and (min-width: 576px) {
        #player-statistics .modal-dialog.modal-lg {
            max-width: 800px;
        }
    }

    .table-row {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        font-family: 'Open Sans';
        height: 50px;
        font-size: 17px;
        letter-spacing: -0.24px;
        color: #000000;
        cursor: pointer;
        transition: all 300ms;
    }

    .table-row.odd {
        background-color: #f3f6f9;
        position: relative;
        z-index: 0;
    }

    .table-row.even {
        background-color: #ffffff;
        position: relative;
        z-index: 1;
        box-shadow: 0 1px 3px 0 #abbed4;
    }

    .table-row.odd:hover, .table-row.even:hover {
        background-color: #d8ffe6;
    }

    .teams {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        font-family: 'Rubik';
        margin-bottom: 40px;
    }

    .team-tab {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        width: 50%;
        height: 30px;
        line-height: 30px;
        font-size: 18px;
        font-weight: bold;
        transition: all 300ms;
        padding-left: 15px;
        padding-right: 15px;
    }

    .team-inner {
        position: relative;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 36px;
    }

    .team-inner:before {
        display: block;
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 3px;
        transition: all 300ms;
    }

    .team-inner:not(.active) {
        color: #aaaaaa;
    }

    .team-inner.active {
        color: #1d252d;
    }

    .team-inner:not(.active):before {
        background-color: #ffffff;
    }

    .team-inner.active:before {
        background-color: #23c373;
    }

    .stat-items, .modal-items {
        font-family: 'Rubik';
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        margin-bottom: 25px;
    }

    .stat-items {
        justify-content: flex-end;
    }

    .modal-items {
        justify-content: flex-start;
    }

    .stat-item, .modal-item {
        display: flex;
        flex-flow: column;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
    }

    .stat-item {
        width: 64px;
    }

    .modal-item {
        width: 56px;
    }

    .stat-text {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        height: 40px;
        line-height: 40px;
    }

    .stat-text.empty {
        color: #cccccc;
    }

    .stat-icon {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 21px;
        width: 21px;
    }

    .penaltyshot-icon {
        background-image: url('/icon/7-m.svg');
    }

    .clock-icon {
        background-image: url('/icon/time.svg');
    }

    .defend-icon {
        background-image: url('/icon/red-goal.svg');
    }

    .goal-icon {
        background-image: url('/icon/green-goal.svg');
    }

    .red-percent-icon {
        background-image: url('/icon/red-stat.svg');
    }

    .percent-icon {
        background-image: url('/icon/stat.svg');
    }

    .yellow-icon {
        background-image: url('/icon/yellow-card.svg');
    }

    .exclude-icon {
        background-image: url('/icon/2-min.svg');
    }

    .red-icon {
        background-image: url('/icon/red-card.svg');
    }

    .blue-icon {
        background-image: url('/icon/blue-card.svg');
    }

    .goalkeepers {
        margin-bottom: 25px;
    }

    .goalkeepers, .players {
        border-top: 1px solid #000000;
        border-bottom: 1px solid #000000;
    }

    .goalkeepers .player-name {
        max-width: calc(100% - 668px);
    }

    .players .player-name {
        max-width: calc(100% - 572px);
    }

    .player-jersey {
        font-weight: 800;
        padding-left: 15px;
        width: 60px;
    }

    .player-name {
        font-weight: 600;
        flex-grow: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .player-goalkeeper {
        width: 32px;
        text-align: center;
        font-weight: bold;
    }

    .nowrap {
        width: 64px;
        border-left: solid 1.2px #aaaaaa;
        text-align: center;
        height: 50px;
        display: flex;
        flex-flow: column;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
    }

    .modal-name {
        font-size: 18px;
        font-weight: bold;
    }

    .modal-year {
        font-weight: normal;
    }

    .modal-team {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .mini-tabs {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        margin-bottom: 15px;
    }

    .mini-tab {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 44px;
        line-height: 16px;
        color: #ffffff;
        font-family: 'Rubik';
        cursor: pointer;
        font-size: 14px;
        letter-spacing: 1px;
        text-align: center;
        text-transform: uppercase;
        transition: all 300ms;
        padding: 0 6px;
    }

    .mini-tab:first-child {
        border-left: 1px solid #f0f3f6;
    }

    .mini-tab.active {
        background-color: #23c373;
    }

    .mini-tab:not(.active) {
        background-color: #1d252d;
    }

    .mini-tab.active:first-child + .mini-tab:nth-child(2):not(.active) {
        border-right: 1px solid #ffffff;
    }

    .mini-tab:not(.active):first-child + .mini-tab:nth-child(2):not(.active) {
        border-left: 1px solid #ffffff;
    }

    @media screen and (max-width: 992px) {
        .player-jersey  {
            width: 40px;
        }

        .stat-item, .nowrap {
            width: 54px;
        }

        .table-row {
            font-size: 15px;
        }

        .goalkeepers .player-name {
            max-width: calc(100% - 558px);
        }

        .players .player-name {
            max-width: calc(100% - 472px);
        }
    }

    @media screen and (max-width: 700px) {
        .outer {
            width: 100%;
            overflow: auto;
        }

        .inner {
            width: 650px;
        }

        .stat-items, .modal-items {
            flex-wrap: wrap;
        }

        .modal-item {
            width: 20%;
        }
    }
</style>