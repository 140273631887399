<template>
    <fragment>
        <div class="stat-title">{{title}}</div>
        <div class="stat-row">
            <div class="stat-number">{{A}}</div>
            <div class="stat-line">
                <div class="left-stat" :style="'width: '+ShowStatistics['A']+'%;'"></div>
                <div class="right-stat" :style="'width: '+ShowStatistics['B']+'%;'"></div>
            </div>
            <div class="stat-number">{{B}}</div>
        </div>
    </fragment>
</template>

<script>
    export default {
        name: 'LineStatistics',
        props: {
            title: String,
            A: Number,
            B: Number,
        },
        methods: {
            rawDataToStat(A, B) {
                if(A === B) {
                    return {
                        A: 50,
                        B: 50,
                    }
                }

                return {
                    A: A / (A + B) * 100,
                    B: B / (A + B) * 100,
                };
            },
        },
        computed: {
            ShowStatistics() {
                return this.rawDataToStat(this.A, this.B);
            },
        }
    }
</script>

<style scoped>
    .stat-title {
        font-weight: bold;
        line-height: 18px;
        text-align: center;
        width: 100%;
        font-size: 18px;
        margin-bottom: 5px;
    }

    .stat-row {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        width: 100%;
        margin-bottom: 25px;
    }

    .stat-number {
        font-weight: bold;
        width: 25px;
    }

    .stat-number:first-child {
        margin-right: 15px;
        text-align: left;
    }

    .stat-number:last-child {
        margin-left: 15px;
        text-align: right;
    }

    .stat-line {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        width: calc(100% - 80px);
    }

    .left-stat, .right-stat {
        height: 10px;
    }

    .left-stat {
        border-right: 1px solid #ffffff;
        background-image: linear-gradient(to right, #6C1722, #f73051);
    }

    .right-stat {
        border-left: 1px solid #ffffff;
        background-image: linear-gradient(to right, #1cb568, #0F5C34);
    }
</style>