<template>
    <div v-if="item.shots" class="bubble" :class="blue ? 'blue' : ''" :style="'top: '+top+'; left: '+left+';'" v-b-tooltip.hover.top :title="tooltip">
        <div class="bubble-area" :style="'opacity: '+item.ratio"/>
        {{item.render}}
    </div>
</template>

<script>
    export default {
        name: 'Bubble',
        props: {
            item: Object,
            top: String,
            left: String,
            tooltip: String,
            blue: Boolean,
        },
    }
</script>

<style scoped>
    .bubble {
        position: absolute;
        z-index: 1;
        transform: translate(-50%, -50%);
        padding: 10px;
    }

    .bubble-area {
        border-radius: 50%;
        overflow: hidden;
        background: radial-gradient(#f73051 30%, transparent 75%);
        width: 100px;
        height: 100px;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
    }

    .bubble.blue .bubble-area {
        background: radial-gradient(#5674f7 30%, transparent 75%);
    }
</style>