<template>
    <div class="not-found">
        Az oldal nem található
    </div>
</template>

<script>
    export default {
        name: 'NotFound',
    }
</script>

<style scoped>
    .not-found {
        font-family: 'Rubik';
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        color: #000000;
        font-size: 20px;
        width: 100vw;
        height: 100vh;
    }
</style>