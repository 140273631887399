import { render, staticRenderFns } from "./TeremShotStatistics.vue?vue&type=template&id=27914ca4&scoped=true&"
import script from "./TeremShotStatistics.vue?vue&type=script&lang=js&"
export * from "./TeremShotStatistics.vue?vue&type=script&lang=js&"
import style0 from "./TeremShotStatistics.vue?vue&type=style&index=0&id=27914ca4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27914ca4",
  null
  
)

export default component.exports