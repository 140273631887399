import { render, staticRenderFns } from "./ItemYellow.vue?vue&type=template&id=f60986fe&scoped=true&"
import script from "./ItemYellow.vue?vue&type=script&lang=js&"
export * from "./ItemYellow.vue?vue&type=script&lang=js&"
import style0 from "./ItemYellow.vue?vue&type=style&index=0&id=f60986fe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f60986fe",
  null
  
)

export default component.exports