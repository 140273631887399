function config() {
    // ez a public socket!
    this.socket_host = "https://teszt.livescore-socket.mksz.hu";
    this.socket_port = 443;
    this.socket_debug = false;
    this.api_url = "https://teszt.bo.mksz.hu";
    // this.sentry_dsn = "https://5dab922e49e14500a88d072321c926a0@sentry.docker.zengo.eu/65";
    this.sentry_dns = false;
}

module.exports = new config();
