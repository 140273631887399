<template>
    <div class="timeline-table">
        <div v-for="(item, i) in sortedItems" :key="i" class="table-row" :class="[i%2 ? 'even' : 'odd', !item.approved && item.rejected ? 'rejected-item' : '']">
            <div class="first-item">{{item.gameTime}}</div>
            <ItemGolStrand v-if="item.action === 'gol'" :item="item"/>
            <ItemGol2p v-if="item.action === 'gol2p'" :item="item"/>
            <ItemRontottStrand v-if="item.action === 'rontott'" :item="item"/>
            <ItemVedettStrand v-if="item.action === 'vedett'" :item="item"/>
            <ItemKapufaStrand v-if="item.action === 'kapufa'" :item="item"/>
            <ItemGol6m v-if="item.action === 'gol6m'" :item="item"/>
            <ItemRontott6m v-if="item.action === 'rontott6m'" :item="item"/>
            <ItemVedett6m v-if="item.action === 'vedett6m'" :item="item"/>
            <ItemKapufa6m v-if="item.action === 'kapufa6m'" :item="item"/>
            <ItemEladott v-if="item.action === 'eladott'" :item="item"/>
            <ItemTechnikai v-if="item.action === 'technikai'" :item="item"/>
            <ItemKiallitas v-if="item.action === 'kiallitas'" :item="item"/>
            <ItemRed v-if="item.action === 'piroslap'" :item="item"/>
            <TimeOut v-if="item.action === 'timeOut'" :item="item"/>
            <Entry v-if="item.action === 'player_entry'" :item="item"/>
            <PeriodChange v-if="item.action === 'periodChange'" :item="item"/>
            <div class="last-item">{{item.gameTime}}</div>
        </div>

        <div v-if="!sortedItems.length" class="itemsEmpty">
            A mérkőzés még nem kezdődött el.
        </div>
    </div>
</template>

<script>
    import ItemGolStrand from '../ItemGolStrand';
    import ItemGol2p from '../ItemGol2p';
    import ItemRontottStrand from '../ItemRontottStrand';
    import ItemVedettStrand from '../ItemVedettStrand';
    import ItemKapufaStrand from '../ItemKapufaStrand';
    import ItemGol6m from '../ItemGol6m';
    import ItemRontott6m from '../ItemRontott6m';
    import ItemVedett6m from '../ItemVedett6m';
    import ItemKapufa6m from '../ItemKapufa6m';
    import ItemEladott from '../ItemEladott';
    import ItemTechnikai from '../ItemTechnikai';
    import ItemRed from '../ItemRed';
    import ItemKiallitas from '../ItemKiallitas';
    import TimeOut from '../TimeOut';
    import Entry from '../Entry';
    import PeriodChange from '../PeriodChange';

    export default {
        name: 'StrandTimeline',
        props: {
            items: Array,
            gameType: String,
        },
        components: {
            ItemGolStrand,
            ItemRontottStrand,
            ItemGol2p,
            ItemVedettStrand,
            ItemKapufaStrand,
            ItemGol6m,
            ItemRontott6m,
            ItemVedett6m,
            ItemKapufa6m,
            ItemEladott,
            ItemTechnikai,
            ItemRed,
            ItemKiallitas,
            TimeOut,
            Entry,
            PeriodChange,
        },
        methods: {
            compareInc: function (a, b) {
                if(Number(a.period) === Number(b.period)) {
                    if(a.gameTime === b.gameTime) {
                        if(a.actionName.includes("kezdete")) {
                            return -1;
                        }

                        if(b.actionName.includes("kezdete")) {
                            return 1;
                        }

                        if(a.clientTime < b.clientTime) {
                            return -1;
                        }

                        if(a.clientTime > b.clientTime) {
                            return 1;
                        }

                        return 0;
                    }

                    if(a.gameTime < b.gameTime) {
                        return -1;
                    }

                    if(a.gameTime > b.gameTime) {
                        return 1;
                    }

                    return 0;
                }

                if (Number(a.period) < Number(b.period)) {
                    return -1;
                }
                if (Number(a.period) > Number(b.period)) {
                    return 1;
                }
                return 0;
            },
        },
        computed: {
            sortedItems: function() {
                let self = this;

                return self.items.sort(self.compareInc);
            }
        },
    }
</script>

<style scoped>
    .timeline-table {
        display: flex;
        width: 100%;
        flex-flow: column-reverse;
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .table-row {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        font-family: "Open Sans";
        width: 100%;
    }

    .table-row.odd {
        background-color: #f3f6f9;
        position: relative;
        z-index: 0;
    }

    .table-row.even {
        background-color: #ffffff;
        position: relative;
        z-index: 1;
        box-shadow: 0 1px 3px 0 #abbed4;
    }

    .table-row.rejected-item {
        opacity: 0.4;
    }

    .table-row.rejected-item > div:not(.main-time) {
        filter: grayscale(1);
    }

    .table-row.rejected-item .time-outer {
        position: relative;
    }

    .table-row.rejected-item .time-outer:after {
        content: "Felülvizsgálat alatt";
        font-size: 10px;
        position: absolute;
        bottom: -9px;
        color: red;
        text-align: center;
        line-height: 15px;
        transform: translateX(-50%);
        left: 50%;
    }

    .table-row.odd .first-item {
        background-image: url('/icon/left_dark_rectangle.svg');
    }
    .table-row.even .first-item {
        background-image: url('/icon/left_light_rectangle.svg');
    }
    .table-row.odd .last-item {
        background-image: url('/icon/right_dark_rectangle.svg');
    }
    .table-row.even .last-item {
        background-image: url('/icon/right_light_rectangle.svg');
    }

    .first-item, .last-item {
        width: 64px;
        height: 50px;
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        font-size: 15px;
    }

    .first-item {
        text-align: left;
        justify-content: flex-start;
        background-size: contain;
        background-position: center left;
        background-repeat: no-repeat;
        padding-left: 7px;
    }

    .last-item {
        text-align: right;
        justify-content: flex-end;
        background-size: contain;
        background-position: center right;
        background-repeat: no-repeat;
        padding-right: 7px;
    }

    @media screen and (max-width: 800px) {
        .first-item {
            background-size: 64px 51px;
            background-position: center right;
            width: 50px;
            font-size: 13px;
        }

        .last-item {
            display: none;
        }
    }
</style>

<style>
    .table-row.odd .time-outer {
        background-color: #f3f6f9;
    }

    .table-row.even .time-outer {
        background-color: #ffffff;
    }

    .table-row.odd .content-outer {
        background-color: #f3f6f9;
    }

    .table-row.even .content-outer {
        background-color: #ffffff;
    }

    .table-row .main-time {
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 50px;
        font-size: 19px;
        white-space: nowrap;
    }

    .table-row .left-content, .table-row .right-content {
        width: calc(50% - 104px);
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
    }

    .table-row .content-outer {
        height: 50px;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        max-width: 100%;
    }

    .table-row .left-content:not(:empty):not(.defender):before, .table-row .right-content:not(:empty):not(.defender):before {
        display: block;
        content: "";
        position: absolute;
        width: calc(100% + 130px);
        height: 1px;
        z-index: -1;
        top: 25px;
    }

    .table-row .left-content:before {
        left: 0;
        background-color: #d9281e;
    }

    .table-row .right-content:before {
        right: 0;
        background-color: #109242;
    }

    .table-row .left-content {
        flex-flow: row;
        flex-direction: row;
        padding-right: 50px;
    }

    .table-row .right-content {
        flex-flow: row-reverse;
        flex-direction: row-reverse;
        padding-left: 50px;
    }

    .table-row .left-content .content-outer {
        padding-left: 6px;
        padding-right: 4px;
    }

    .table-row .right-content .content-outer {
        padding-left: 4px;
        padding-right: 6px;
    }

    .table-row .left-content .table-icon {
        background-position: center right;
        margin-right: 10px;
    }

    .table-row .right-content .table-icon {
        background-position: center left;
        margin-left: 10px;
    }

    .table-row .table-icon {
        background-size: contain;
        background-repeat: no-repeat;
        min-width: 30px;
        width: 30px;
        height: 30px;
    }

    .table-row .table-jersey {
        font-size: 19px;
        font-weight: 800;
        letter-spacing: -1px;
        white-space: nowrap;
    }

    .table-row .left-content .table-jersey {
        margin-right: 10px;
    }

    .table-row .right-content .table-jersey {
        margin-left: 10px;
    }

    .table-row .table-player {
        font-size: 17px;
        font-weight: 600;
        letter-spacing: -0.23px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .table-row .table-text {
        font-size: 17px;
        font-weight: 600;
        letter-spacing: -0.23px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .table-row .time-outer {
        padding-left: 4px;
        padding-right: 4px;
    }

    @media screen and (max-width: 992px) {
        .table-row .left-content .table-jersey {
            margin-right: 5px;
        }

        .table-row .right-content .table-jersey {
            margin-left: 5px;
        }

        .table-row .left-content .table-icon {
            margin-right: 5px;
        }

        .table-row .right-content .table-icon {
            margin-left: 5px;
        }
    }

    @media screen and (max-width: 800px) {
        .table-row .main-time {
            font-size: 14px;
            width: 48px;
            letter-spacing: -0.5px;
        }

        .table-row .left-content, .table-row .right-content {
            width: calc(50% - 49px);
        }

        .table-row .left-content {
            padding-right: 15px;
        }

        .table-row .right-content {
            padding-left: 15px;
        }

        .table-row .table-jersey {
            font-size: 16px;
        }

        .table-row .table-player {
            font-size: 14px;
            letter-spacing: -1px;
        }

        .table-row .table-text {
            font-size: 14px;
            letter-spacing: -1px;
        }
    }

    @media screen and (max-width: 700px) {
        .table-row .table-icon {
            min-width: 24px;
            width: 24px;
            height: 24px;
        }
    }
</style>