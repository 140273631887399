<template>
    <fragment>
        <div class="left-content">
            {{item.actionName}}
        </div>
        <div class="main-time">
            <div class="time-outer">
                {{item.scores}}
            </div>
        </div>
        <div class="right-content">
            {{item.actionName}}
        </div>
    </fragment>
</template>

<script>
    export default {
        name: 'PeriodChange',
        props: {
            item: Object,
        },
    }
</script>

<style scoped>
    .left-content {
        padding-left: 40px;
    }

    .right-content {
        padding-right: 40px;
    }

    .left-content, .right-content {
        font-weight: bold;
    }

    .left-content:before, .right-content:before {
        content: none !important;
        display: none !important;
    }
</style>