<template>
    <fragment>
        <Header v-if="idExist() && !is404()" :id="id" :szezonid="szezonid" :teams="teams" :gameType="gameType" :statistics="statistics" :matchdate="matchdate" :venue="venue" :competition="competition" :age="age" :gender="gender" :lastPeriod="lastPeriod"/>
        <router-view v-if="idExist() || is404()" :id="id" :szezonid="szezonid" :teams="teams" :gameType="gameType" :players="players" :playtime="playtime" :items="timeline" :statistics="statistics"/>
    </fragment>
</template>

<script>
    // Components
    import Header from './components/Header.vue';
    import TimeFormatter from '@/TimeFormatter';
    import io from "socket.io-client";
    import axios from 'axios'

    import dotenv from 'dotenv';
    dotenv.config()

    if(process.env.VUE_APP_APPLICATION_ENV) {
        window.config = require('./config_' + process.env.VUE_APP_APPLICATION_ENV);
    }
    else {
        window.config = require('./config');
    }

    let socket = io.connect(`${window.config.socket_host}:${window.config.socket_port}`);

    export default {
        name: 'App',
        data: function () {
            return {
                gameType: 'TEREM',
                initDone: false,
                storedItems: [],
                passedPeriods: [],
                items: [],
                teams: {
                    A: {},
                    B: {},
                },
                players: {
                    A: [],
                    B: [],
                },
                statistics: {
                    A: {},
                    B: {},
                },
                periods: [],
                defaultStats: {
                    defendedFailed: 0,
                    defendedFailedPenalty: 0,
                    defendedSuccess: 0,
                    defendedSuccessPenalty: 0,
                    gol: 0,
                    gol2p: 0,
                    gol6m: 0,
                    gol7m: 0,
                    rontott: 0,
                    rontott6m: 0,
                    rontott7m: 0,
                    vedett: 0,
                    vedett6m: 0,
                    vedett7m: 0,
                    kapufa: 0,
                    kapufa6m: 0,
                    kapufa7m: 0,
                    eladott: 0,
                    technikai: 0,
                    kiallitas: 0,
                    sargalap: 0,
                    piroslap: 0,
                    keklap: 0,
                    timeout: 0,
                },
                playtime: {},
                venue: '',
                competition: '',
                age: '',
                gender: '',
                matchdate: '',
                loaded: false
            }
        },
        components: {
            Header,
        },
        created() {
            let self = this;

            if (!self.idExist() && !self.is404()) {
                self.$router.push({path: '/404_not_found'});
            }
        },
        mounted() {
            let self = this;

            if (!self.idExist() && !self.is404()) {
                self.$router.push({path: '/404_not_found'});
            } else if(!self.is404()) {
                if (self.joinRoom(0, self.id)) {
                    self.getData();
                    self.getMatchData();
                }

                socket.on("nodatafound", () => {
                    self.loaded = false;
                    axios.get(window.config.api_url + '/public/api/v1/versenyek/digital-report/esemeny/' + self.id, {
                        headers: {
                            "MKSZ-AUTH": "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJta3N6LWh1LXVzZXIiLCJhdXRob3JpdGllcyI6W10sImFrdGl2U3plcnZlemV0SWQiOiIxIiwiYWt0aXZTemVydmV6ZXROZXYiOiJta3N6LWh1LXVzZXIiLCJpYXQiOjE1OTgyODI3NjB9.xr0QeV30lNXysCHiPePQYiZBlr8h6krWMP0z0x3EZ_xBd4yWdxRXKXAyMqVga6gXlsCekOfAQgY6QKTUOkYw-A"
                        }
                    }).then(function(response) {
                        response.data.forEach(function (item) {
                            self.newMatchEvent(JSON.parse(item.event));
                        });
                        self.loaded = true;
                    }).catch(() => {
                        console.clear();
                        setTimeout(function() {
                            window.location.reload()
                        }, 60000);
                        // self.$router.push({path: "/404_not_found"});
                    });
                });
            }
        },
        methods: {
            idExist: function () {
                // @TODO JELENEG mivel a socketben nem feltétlen szám, hanem most string is lehet a meccs azonosító, ezért nem jó az isnan vizsgálat
                // return !isNaN(this.id) && this.id > 0;
                return typeof this.id !== 'undefined' && this.id !== '';
            },
            is404: function () {
                return this.$route.path === '/404_not_found';
            },
            joinRoom: function (szezonid, id) {
                return socket.emit("join", "Merkozes_" + szezonid + "_" + id);
            },
            getArrayObject(arr, key, value) {
                let rtn = false;

                arr.forEach(function (item) {
                    if(!rtn && item[key] && item[key] === value) {
                        rtn = item;
                    }
                });

                return rtn;
            },
            getArrayIndex(arr, key, value) {
                let rtn = false;

                arr.forEach(function (item, index) {
                    if(!rtn && item[key] && item[key] === value) {
                        rtn = index;
                    }
                });

                return rtn;
            },
            /**
             * A csatlakozásig bekerült események
             */
            getMatchData: function () {
                let self = this;
                socket.on("matchdata", (data) => {
                    return self.newMatchEvent(data);
                });
                self.loaded = true;
            },
            /**
             * Az új események
             */
            getData: function () {
                let self = this;
                socket.on("data", (data) => {
                    return self.newMatchEvent(data);
                });
            },
            newMatchEvent: function(data) {
                let self = this;
                let existIndex = self.getArrayIndex(self.items, 'UUID', data.UUID);

                if(!data.deleted) {
                    if(existIndex === false) {
                        self.addNewRow(data);
                    }
                    else {
                        self.items = self.items.filter(function (item) {
                            return data.UUID !== item.UUID;
                        });
                        self.addNewRow(data);
                    }
                }
                else {
                    if(data.deleted && existIndex) {
                        self.items = self.items.filter(function (item) {
                            return data.UUID !== item.UUID;
                        });
                    }
                }
                return true;
            },
            addNewRow: function (data) {
                let self = this;

                if(data && data.action) {
                    switch (data.action) {
                        case('periodchange'): {
                            if(!self.initDone) {
                                self.storedItems.push(data);
                            } else {
                                if(data.period !== '1') {
                                    let period = self.getArrayObject(self.periods, 'value', data.period);

                                    if(period && !self.passedPeriods.includes(data.period)) {
                                        let nextPeriod = false;

                                        self.periods.forEach(function (period) {
                                            if(!nextPeriod && !self.passedPeriods.includes(period.value)) {
                                                nextPeriod = period.value;
                                            }
                                        });

                                        if(nextPeriod === data.period) {
                                            self.passedPeriods.push(data.period);

                                            self.items.push({
                                                UUID: 'period_'+data.period,
                                                clientTime: data.clientTime,
                                                action: 'periodChange',
                                                actionName: period.text + (['zero','extendedzero'].includes(period.type) ? '' : ' kezdete'),
                                                gameTime: TimeFormatter.intToString(period.gameTime - (['zero','extendedzero'].includes(period.type) ? 0 : period.duration)),
                                                period: data.period,
                                            });
                                        }
                                    }
                                }
                            }

                            break;
                        }
                        case('init'): {
                            let periods = [];
                            let periodSum = 0;

                            if(data && data.params && data.params.periods) {
                                data.params.periods.forEach(function (period) {
                                    let periodLength = 0;

                                    if(!['zero','extendedzero'].includes(period.type)) {
                                        periodLength = period.type === 'extended' ? 300 : data.params.period_length*60;
                                    }

                                    periodSum += periodLength;

                                    periods.push({
                                        ...period,
                                        duration: periodLength,
                                        gameTime: periodSum,
                                    });
                                });

                                if(data.params.rule === 'STRAND') {
                                    self.gameType = 'STRAND';
                                } else {
                                    self.gameType = 'TEREM';
                                }

                                self.periods = periods;

                                self.teams = {
                                    A: {
                                        short: data.params.team_a_shortname,
                                        full: data.params.team_a_name,
                                        // logo: '/logo/tatabanya.png',
                                        score: 0,
                                    },
                                    B: {
                                        short: data.params.team_b_shortname,
                                        full: data.params.team_b_name,
                                        // logo: '/logo/szeged.png',
                                        score: 0,
                                    },
                                };

                                if (data.params.team_a_name && data.params.team_b_name) {
                                    document.title = data.params.team_a_name + " - " + data.params.team_b_name + " | MKSZ élő mérkőzés közvetítés";
                                } else if (data.params.team_a_shortname && data.params.team_b_shortname) {
                                    document.title = data.params.team_a_shortname + " - " + data.params.team_b_shortname + " | MKSZ élő mérkőzés közvetítés";
                                }

                                if (data.params.letesitmeny_nev) {
                                    self.venue = data.params.letesitmeny_nev;
                                }

                                if (data.params.alverseny) {
                                    self.competition = data.params.alverseny;
                                }

                                if (data.params.korosztaly) {
                                    self.age = data.params.korosztaly;
                                }

                                if (data.params.datum_teny && data.params.ido) {
                                    self.matchdate = data.params.datum_teny + ' ' + data.params.ido;
                                }

                                self.players = {
                                    A: [
                                        ...self.buildPersons(data.params.team_a_players, 'player'),
                                        ...self.buildPersons(data.params.team_a_officials, 'official'),
                                        ...self.buildPersons(data.params.team_a_players_entry, 'entry'),
                                    ],
                                    B: [
                                        ...self.buildPersons(data.params.team_b_players, 'player'),
                                        ...self.buildPersons(data.params.team_b_officials, 'official'),
                                        ...self.buildPersons(data.params.team_b_players_entry, 'entry'),
                                    ],
                                };

                                self.initDone = true;

                                if(self.storedItems.length) {
                                    let tempStoredItems = [...self.storedItems];

                                    tempStoredItems.forEach(function (item) {
                                        self.addNewRow(item);
                                    });
                                }
                            }

                            break;
                        }
                        case('player_entry'): {
                            if(!self.initDone) {
                                self.storedItems.push(data);
                            } else {
                                let team = data.params.player.team === "teamA" ? 'A' : 'B';
                                let playerIndex = self.getArrayIndex(self.players[team], 'id', data.params.player.id);

                                if (playerIndex !== false) {
                                    self.players[team][playerIndex].player = true;
                                    self.players[team][playerIndex].goalkeeper = Boolean(data.params.player.goalkeeper);
                                    self.players[team][playerIndex].entry = false;
                                } else {
                                    self.players[team].push({
                                        ...data.params.player,
                                        player: true,
                                        goalkeeper: Boolean(data.params.player.goalkeeper),
                                        official: false,
                                        entry: false,
                                    });
                                }

                                self.items.push({
                                    ...data,
                                    gameTime: '00:00'
                                });
                            }

                            break;
                        }
                        case('gamedatachange'): {
                            if(!self.initDone) {
                                self.storedItems.push(data);
                            } else {
                                if (data.period && data.params && data.period === '1' && ["team_a_actual_goalkeeper", "team_b_actual_goalkeeper"].includes(data.params.name) && !self.passedPeriods.includes(data.period)) {
                                    if(self.gameType === 'TEREM' || data.params.gametime !== 0) {
                                        self.passedPeriods.push(data.period);

                                        self.items.push({
                                            UUID: 'period_'+data.period,
                                            clientTime: data.clientTime,
                                            action: 'periodChange',
                                            actionName: 'I. félidő kezdete',
                                            gameTime: '00:00',
                                            period: data.period,
                                        });
                                    }
                                }
                            }

                            break;
                        }
                        case('playtime'): {
                            self.playtime = data.params.playtime;
                            break;
                        }
                        default:
                            if(!self.initDone) {
                                self.storedItems.push(data);
                            } else {
                                let prevPeriodValue = self.prevPeriod(data.period);

                                if(data.period && !self.passedPeriods.includes(prevPeriodValue)) {
                                    let period = self.getArrayObject(self.periods, 'value', prevPeriodValue);

                                    self.passedPeriods.push(prevPeriodValue);

                                    self.items.push({
                                        UUID: 'period_'+period.value,
                                        clientTime: data.clientTime,
                                        action: 'periodChange',
                                        actionName: period.text + (['zero','extendedzero'].includes(period.type) ? '' : ' kezdete'),
                                        gameTime: TimeFormatter.intToString(period.gameTime - (['zero','extendedzero'].includes(period.type) ? 0 : period.duration)),
                                        period: period.value,
                                    });
                                }

                                if(data.period && !self.passedPeriods.includes(data.period)) {
                                    let period = self.getArrayObject(self.periods, 'value', data.period);

                                    self.passedPeriods.push(data.period);

                                    self.items.push({
                                        UUID: 'period_'+period.value,
                                        clientTime: data.clientTime,
                                        action: 'periodChange',
                                        actionName: period.text + (['zero','extendedzero'].includes(period.type) ? '' : ' kezdete'),
                                        gameTime: TimeFormatter.intToString(period.gameTime - (['zero','extendedzero'].includes(period.type) ? 0 : period.duration)),
                                        period: period.value,
                                    });
                                }

                                self.items.push({
                                    ...data,
                                    gameTime: TimeFormatter.intToString(data.totalGameTime)
                                });
                            }

                            break;
                    }
                }
            },
            prevPeriod: function(value) {
                let self = this;
                let found = false;
                let rtn = '1';

                self.periods.forEach(function (period) {
                    if(!found && period.value === value) {
                        found = true;
                    }

                    if(!found) {
                        rtn = period.value;
                    }
                });

                return rtn;
            },
            buildPersons: function (persons, type) {
                let rtn = [];

                if(persons) {
                    persons.forEach(function (person) {
                        rtn.push({
                            ...person,
                            player: false,
                            official: false,
                            entry: false,
                            [type]: true,
                        });
                    });
                }

                return rtn;
            },
            compareInc: function (a, b) {
                if(Number(a.period) === Number(b.period)) {
                    if(a.gameTime === b.gameTime) {
                        if(a.clientTime < b.clientTime) {
                            return -1;
                        }

                        if(a.clientTime > b.clientTime) {
                            return 1;
                        }

                        return 0;
                    }

                    if(a.gameTime < b.gameTime) {
                        return -1;
                    }

                    if(a.gameTime > b.gameTime) {
                        return 1;
                    }

                    return 0;
                }

                if (Number(a.period) < Number(b.period)) {
                    return -1;
                }
                if (Number(a.period) > Number(b.period)) {
                    return 1;
                }
                return 0;
            },
            addStat: function (item) {
                let self = this;
                let invTeam = (item.team === 'A') ? 'B' : 'A';

                ['A', 'B'].forEach(function (team) {
                    if(!self.statistics[team][item.period]) {
                        self.statistics[team][item.period] = {...self.defaultStats};
                    }
                });

                switch (item.action) {
                    case('kiallitas'): {
                        self.statistics[item.team][item.period].kiallitas++;
                        break;
                    }
                    case('2perc'): {
                        self.statistics[item.team][item.period].kiallitas++;
                        break;
                    }
                    case('4perc'): {
                        self.statistics[item.team][item.period].kiallitas+=2;
                        break;
                    }
                    case('timeOut'): {
                        self.statistics[item.team][item.period].timeout++;
                        break;
                    }
                    default:
                        self.statistics[item.team][item.period][item.action]++;

                        switch(item.action) {
                            case('gol'):
                                self.statistics[invTeam][item.period].defendedFailed++;
                                break;
                            case('gol2p'):
                                self.statistics[invTeam][item.period].defendedFailed++;
                                break;
                            case('gol6m'):
                                self.statistics[invTeam][item.period].defendedFailedPenalty++;
                                break;
                            case('gol7m'):
                                self.statistics[invTeam][item.period].defendedFailedPenalty++;
                                break;
                            case('vedett'):
                                self.statistics[invTeam][item.period].defendedSuccess++;
                                break;
                            case('vedett6m'):
                                self.statistics[invTeam][item.period].defendedSuccessPenalty++;
                                break;
                            case('vedett7m'):
                                self.statistics[invTeam][item.period].defendedSuccessPenalty++;
                                break;
                        }

                        break;
                }
            },
            addPlayerStat: function (item) {
                let self = this;
                let playerIndex = self.getArrayIndex(self.players[item.team], 'id', item.player.id);

                if(playerIndex === false) {
                    return;
                }

                switch (item.action) {
                    case('kiallitas'): {
                        self.players[item.team][playerIndex].statistics.kiallitas++;
                        break;
                    }
                    case('2perc'): {
                        self.players[item.team][playerIndex].statistics.kiallitas++;
                        break;
                    }
                    case('4perc'): {
                        self.players[item.team][playerIndex].statistics.kiallitas+=2;
                        break;
                    }
                    default:
                        self.players[item.team][playerIndex].statistics[item.action]++;
                        break;
                }
            },
        },
        computed: {
            id: function () {
                if (this.$router.history &&
                    this.$router.history.current &&
                    this.$router.history.current.params &&
                    this.$router.history.current.params.id
                ) {
                    return this.$router.history.current.params.id;
                }

                return undefined;
            },
            szezonid: function () {
              /*
              if (this.$router.history &&
                  this.$router.history.current &&
                  this.$router.history.current.params &&
                  this.$router.history.current.params.szezonid
              ) {
                return this.$router.history.current.params.szezonid;
              }
              */

              return "0";
            },
            lastPeriod: function () {
                if(this.passedPeriods.length) {
                    return this.passedPeriods[this.passedPeriods.length - 1];
                }

                return '0';
            },
            timeline: function () {
                let self = this;
                let rtn = [];
                let period = '1';
                let szetlovesTime = false;
                let szetlovesPeriod = false;

                self.statistics = {
                    A: {},
                    B: {},
                };

                ['A', 'B'].forEach(function (team) {
                    self.teams[team].score = 0;
                    self.players[team].forEach(function (obj, index) {
                        self.players[team][index].statistics = {...self.defaultStats};
                    });
                });

                self.items.forEach(item => {
                    if(item.actionName === 'Szétlövés') {
                        szetlovesTime = item.gameTime;
                        szetlovesPeriod = Number(item.period);
                    }
                });

                self.items.sort(self.compareInc).forEach(item => {
                    if(self.gameType === 'STRAND' && period !== item.period) {
                        period = item.period;
                        self.teams['A'].score = 0;
                        self.teams['B'].score = 0;
                    }

                    if(item.action === 'periodChange') {
                        rtn.push({
                            ...item,
                            scores: item.actionName === 'Szétlövés' ? '0 : 0' : (item.actionName === 'Félidő' || item.actionName.includes('vége')) ? '' : self.teams['A'].score+' : '+self.teams['B'].score,
                        });
                    }
                    else if(item.action === 'player_entry') {
                        if(item.params.player.team) {
                            rtn.push({
                                UUID: item.UUID,
                                clientTime: item.clientTime,
                                action: item.action,
                                actionName: "Játékos benevezés",
                                gameTime: '00:00',
                                scores: self.teams['A'].score+' : '+self.teams['B'].score,
                                team: item.params.player.team.slice(item.params.player.team.length - 1),
                                period: item.period,
                                selectedArea: null,
                                selectedGateArea: 0,
                                player: {
                                    id: item.params.player.id,
                                    jersey: item.params.player.jersey,
                                    name: item.params.player.name,
                                }
                            });
                        }
                    }
                    else if(item.usertype === 'sub') {
                        // Ez a cseréről jön, ezzel nem csinálunk semmit
                    }
                    else {
                        if(item.params.selectedTeamId) {
                            let team = item.params.selectedTeamId.slice(item.params.selectedTeamId.length - 1);

                            if (['gol', 'gol7m'].includes(item.action)) {
                                self.teams[team].score++;
                            }

                            if (item.action === 'gol2p' || item.action === 'gol6m') {
                                self.teams[team].score+=2;
                            }

                            /*
                            self.teams['A'].score = item.params.teamScore.teamA;
                            self.teams['B'].score = item.params.teamScore.teamB;
                            */

                            let row = {
                                UUID: item.UUID,
                                clientTime: item.clientTime,
                                action: item.action,
                                actionName: item.params.selectedAction.actionName,
                                approved: item.params.approved,
                                rejected: item.params.rejectReason,
                                gameTime: (szetlovesPeriod && szetlovesTime && szetlovesPeriod <= Number(item.period)) ? szetlovesTime : item.gameTime,
                                scores: self.teams['A'].score + ' : ' + self.teams['B'].score,
                                team: team,
                                period: item.period,
                                selectedArea: item.params.selectedArea,
                                selectedGateArea: item.params.selectedGateArea,
                                shotType: item.params.selectedActionType ? item.params.selectedActionType : null,
                                player: {
                                    id: 0,
                                    jersey: '',
                                    name: '',
                                }
                            };

                            if (item.params.selectedPlayer) {
                                let player = self.getArrayObject(self.players[team], 'id', item.params.selectedPlayer);

                                //Direkt van így!
                                if (player !== false) {
                                    row.player = {
                                        id: player.id,
                                        jersey: player.jersey,
                                        name: player.name,
                                    };
                                }
                            }

                            if (['gol', 'gol2p', 'gol6m', 'gol7m', 'vedett', 'vedett6m', 'vedett7m'].includes(item.action)) {
                                let invTeam = (team === 'A') ? 'B' : 'A';

                                if (item.params.otherTeamGoalKeeper && item.params.otherTeamGoalKeeper.id) {
                                    let goalkeeperIndex = self.getArrayIndex(self.players[invTeam], 'id', item.params.otherTeamGoalKeeper.id);

                                    if(goalkeeperIndex !== false) {
                                        let goalkeeper = self.players[invTeam][goalkeeperIndex];

                                        row.defended = {
                                            id: goalkeeper.id,
                                            jersey: goalkeeper.jersey,
                                            name: goalkeeper.name,
                                        };

                                        switch (item.action) {
                                            case('gol'):
                                                self.players[invTeam][goalkeeperIndex].statistics.defendedFailed++;
                                                break;
                                            case('gol2p'):
                                                self.players[invTeam][goalkeeperIndex].statistics.defendedFailed++;
                                                break;
                                            case('gol6m'):
                                                self.players[invTeam][goalkeeperIndex].statistics.defendedFailedPenalty++;
                                                break;
                                            case('gol7m'):
                                                self.players[invTeam][goalkeeperIndex].statistics.defendedFailedPenalty++;
                                                break;
                                            case('vedett'):
                                                self.players[invTeam][goalkeeperIndex].statistics.defendedSuccess++;
                                                break;
                                            case('vedett6m'):
                                                self.players[invTeam][goalkeeperIndex].statistics.defendedSuccessPenalty++;
                                                break;
                                            case('vedett7m'):
                                                self.players[invTeam][goalkeeperIndex].statistics.defendedSuccessPenalty++;
                                                break;
                                        }
                                    }
                                } else {
                                    row.defended = {
                                        id: 0,
                                        jersey: '',
                                        name: 'Üres kapu',
                                    };
                                }
                            }

                            if (item.action !== 'timeOut' || item.params.type !== 'lost') {
                                rtn.push(row);
                                self.addStat(row);
                            }

                            if (item.action !== 'timeOut') {
                                self.addPlayerStat(row);
                            }
                        }
                    }
                });

                return rtn;
            }
        }
    }
</script>

<style>
    html body {
        color: #000000;
        background-color: #f0f3f6;
        position: relative;
        z-index: 1;
    }

    a, a:hover, a:focus, a:active, a:visited {
        text-decoration: none !important;
        outline: none !important;
    }

    *:focus {
        outline: none !important;
    }

    body:before {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        background-image: url('/image/gray-bg-left.png');
        background-size: contain;
        background-position: top left;
        background-repeat: no-repeat;

        max-width: 831px;
        max-height: 841px;

        width: 50vw;
        height: 841px;
    }

    body:after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;

        background-image: url('/image/gray-bg-right.png');
        background-size: contain;
        background-position: top right;
        background-repeat: no-repeat;

        max-width: 883px;
        max-height: 861px;

        width: 50vw;
        height: 861px;
    }

    .main-container {
        background-color: #ffffff;
        padding: 35px 75px 50px 75px;
        margin-bottom: 25px;
    }

    .modal-header .close {
        font-size: 30px !important;
        padding: 10px !important;
        line-height: 30px !important;
        margin: 0 0 0 auto !important;
    }

    .modal-header {
        display: flex !important;
        flex-flow: row !important;
        flex-direction: row !important;
        align-items: center !important;
        justify-content: flex-start !important;
        flex-wrap: nowrap !important;
        padding: 0 15px !important;
    }

    .modal-body {
        padding: 15px !important;
    }

    .itemsEmpty {
        text-align: center;
        width: 100%;
        height: 50px;
        display: flex;
        flex-flow: row;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .tooltip-inner {
        white-space: nowrap;
        max-width: none !important;
    }

    @media screen and (max-width: 992px) {
        .main-container {
            padding: 25px 10px 25px 10px;
        }
    }

    @media screen and (max-width: 600px) {
        .container-lg {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }

        .container-lg > .row {
          margin-left: -10px !important;
          margin-right: -10px !important;
        }

        .modal-header {
            padding: 0 10px !important;
        }

        .modal-body {
            padding: 10px !important;
        }
    }
</style>