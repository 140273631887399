<template>
    <b-container fluid="lg">
        <div class="main-container">
            <TeremTimeline v-if="gameType === 'TEREM'" :items="items" :gameType="gameType"/>
            <StrandTimeline v-if="gameType === 'STRAND'" :items="items" :gameType="gameType"/>
        </div>
    </b-container>
</template>

<script>
    import TeremTimeline from '../components/terem/TeremTimeline';
    import StrandTimeline from '../components/strand/StrandTimeline';

    export default {
        name: 'TimelineScreen',
        components: {
            TeremTimeline,
            StrandTimeline,
        },
        props: {
            id: String,
            szezonid: String,
            teams: Object,
            players: Object,
            statistics: Object,
            items: Array,
            gameType: String,
        },
    }
</script>