class Stat
{
    get(stat, team, type, penalty) {
        let self = this;
        let rtn = 0;

        Object.keys(stat[team]).forEach(function (key) {
            switch (type) {
                case('success'):
                    rtn += self.getSuccessShots(stat[team][key], penalty);
                    break;
                case('failed'):
                    rtn += self.getFailedShots(stat[team][key], penalty);
                    break;
                case('all'):
                    rtn += self.getFailedShots(stat[team][key], penalty) + self.getSuccessShots(stat[team][key], penalty);
                    break;
                case('shot'):
                    rtn += self.getShots(stat[team][key], penalty);
                    break;
                default:
                    rtn += stat[team][key][type];
                    break;
            }
        });

        return rtn;
    }

    getShots(item, penalty) {
        if(typeof penalty === "undefined") {
            return item.defendedFailed + item.defendedFailedPenalty + item.defendedSuccess + item.defendedSuccessPenalty;
        }

        if(penalty) {
            return item.defendedFailedPenalty + item.defendedSuccessPenalty;
        }

        return item.defendedFailed + item.defendedSuccess;
    }

    getSuccessShots(item, penalty) {
        if(typeof penalty === "undefined") {
            return item.gol + item.gol2p + item.gol6m + item.gol7m;
        }

        if(penalty) {
            return item.gol6m + item.gol7m;
        }

        return item.gol + item.gol2p;
    }

    getFailedShots(item, penalty) {
        if (typeof penalty === "undefined") {
            return item.rontott +
                item.rontott6m +
                item.rontott7m +
                item.kapufa +
                item.kapufa6m +
                item.kapufa7m +
                item.vedett +
                item.vedett6m +
                item.vedett7m;
        }

        if (penalty) {
            return item.rontott6m +
                item.rontott7m +
                item.kapufa6m +
                item.kapufa7m +
                item.vedett6m +
                item.vedett7m;
        }

        return item.rontott +
            item.kapufa +
            item.vedett;
    }
}

module.exports = new Stat();
