<template>
    <fragment>
        <div class="left-content">
            <div v-if="item.team === 'A'" class="content-outer">
                <div class="table-icon" v-b-tooltip.hover.top title="Időkérés"/>
                <div class="table-text">{{item.actionName}}</div>
            </div>
        </div>
        <div class="main-time">
            <div class="time-outer">
                {{item.scores}}
            </div>
        </div>
        <div class="right-content">
            <div v-if="item.team === 'B'" class="content-outer">
                <div class="table-text">{{item.actionName}}</div>
                <div class="table-icon" v-b-tooltip.hover.top title="Időkérés"/>
            </div>
        </div>
    </fragment>
</template>

<script>
    export default {
        name: 'TimeOut',
        props: {
            item: Object,
        },
    }
</script>

<style scoped>
    .table-icon:after {
        content: "T";
        color: #aaaaaa;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        font-weight: bold;
        line-height: 30px;
    }
</style>