<template>
    <fragment>
        <div class="left-content" :class="item.team === 'B' ? 'defender' : item.shotType">
            <div v-if="item.team === 'A' || item.defended.name" class="content-outer">
                <div class="table-icon" :class="item.team === 'B' ? 'blind' : ''" v-b-tooltip.hover.top :title="item.team === 'A' ? shotName() : item.defended.jersey ? 'Kapus' : 'Üres kapu'"/>
                <div class="table-jersey">{{item.team === 'A' ? item.player.jersey : item.defended.jersey}}</div>
                <div class="table-player">{{item.team === 'A' ? item.player.name : item.defended.name}}</div>
            </div>
        </div>
        <div class="main-time goal">
            <div class="time-outer">
                {{item.scores}}
            </div>
        </div>
        <div class="right-content" :class="item.team === 'A' ? 'defender' : item.shotType">
            <div v-if="item.team === 'B' || item.defended.name" class="content-outer">
                <div class="table-player">{{item.team === 'B' ? item.player.name : item.defended.name}}</div>
                <div class="table-jersey">{{item.team === 'B' ? item.player.jersey : item.defended.jersey}}</div>
                <div class="table-icon" :class="item.team === 'A' ? 'blind' : ''" v-b-tooltip.hover.top :title="item.team === 'B' ? shotName() : item.defended.jersey ? 'Kapus' : 'Üres kapu'"/>
            </div>
        </div>
    </fragment>
</template>

<script>
    export default {
        name: 'ItemGolStrand',
        props: {
            item: Object,
        },
        methods: {
            shotName: function() {
                let rtn = "Gól";

                switch (this.item.shotType) {
                    case("normalShot"):
                        rtn = "Normál lövés gól";
                        break;
                    case("porgoShot"):
                        rtn = "Pörgő lövés gól";
                        break;
                    case("specialistaShot"):
                        rtn = "Specialista lövés gól";
                        break;
                    case("kinaiShot"):
                        rtn = "Kínai lövés gól";
                        break;
                    case("direktShot"):
                        rtn = "Direkt lövés gól";
                        break;
                    default:
                        break;
                }

                return rtn;
            }
        },
    }
</script>

<style scoped>
    .main-time {
        font-weight: 800;
    }

    .table-icon.blind {
        background-image: url('/icon/gate.svg');
    }

    .left-content .table-icon:not(.blind), .right-content .table-icon:not(.blind) {
        background-image: url('/icon/green-goal.svg');
    }

    .left-content.normalShot .table-icon:not(.blind), .right-content.normalShot .table-icon:not(.blind) {
        background-image: url('/icon/normal-icon-green-1.svg');
    }

    .left-content.porgoShot .table-icon:not(.blind), .right-content.porgoShot .table-icon:not(.blind) {
        background-image: url('/icon/porgo-icon-green-1.svg');
    }

    .left-content.specialistaShot .table-icon:not(.blind), .right-content.specialistaShot .table-icon:not(.blind) {
        background-image: url('/icon/specialista-icon-green-1.svg');
    }

    .left-content.kinaiShot .table-icon:not(.blind), .right-content.kinaiShot .table-icon:not(.blind) {
        background-image: url('/icon/kinai-icon-green-1.svg');
    }

    .left-content.direktShot .table-icon:not(.blind), .right-content.direktShot .table-icon:not(.blind) {
        background-image: url('/icon/direkt-icon-green-1.svg');
    }
</style>