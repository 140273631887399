<template>
    <div>
        <TeremPlayerStatisticsScreen v-if="gameType === 'TEREM'" :id="id" :szezonid="szezonid" :teams="teams" :players="players" :items="items" :statistics="statistics" :playtime="playtime"/>
        <StrandPlayerStatisticsScreen v-if="gameType === 'STRAND'" :id="id" :szezonid="szezonid" :teams="teams" :players="players" :items="items" :statistics="statistics" :playtime="playtime"/>
    </div>
</template>

<script>
    import TeremPlayerStatisticsScreen from '../components/terem/TeremPlayerStatisticsScreen';
    import StrandPlayerStatisticsScreen from '../components/strand/StrandPlayerStatisticsScreen';

    export default {
        name: 'PlayerStatisticsScreen',
        components: {
            TeremPlayerStatisticsScreen,
            StrandPlayerStatisticsScreen,
        },
        props: {
            id: String,
            szezonid: String,
            teams: Object,
            players: Object,
            statistics: Object,
            items: Array,
            playtime: Object,
            gameType: String
        }
    }
</script>